import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: var(--color-gray-300);
  position: sticky;
  top: 0;
  z-index: 1000;
`;

export const NavbarContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 999;
  background: var(--v2-color-white);
`;

export const ContentWrapper = styled.div`
  width: 100%;
  background-color: var(--v2-color-dropdown-right);
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Header = styled.header`
  justify-content: space-between;
  align-items: center;
  padding: 22px 80px;
`;

export const Tag = styled.span`
  font-size: 14px;
  background: var(--color-gray-50);
  border-radius: 30px;
  font-weight: 500;
  color: var(--color-gray-900);
  text-align: center;
  width: fit-content;
  height: fit-content;
  line-height: 1.5;
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderDescription = styled.div<{ scrolled: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.scrolled ? "row" : "column")};
  align-items: ${(props) => (props.scrolled ? "center" : "")};
  gap: 16px;
  & > div {
    align-items: baseline;
  }
`;

export const Title = styled.div<{ scrolled: boolean }>`
  font-size: ${(props) => (props.scrolled ? "24px" : "40px")};
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: ${(props) => (props.scrolled ? " 0.48px" : "0.8px")};
  color: var(--color-text-primary);
  transition: all 0.2s ease;
`;

export const Description = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: var(--color-text-primary);
  text-transform: capitalize;
`;

export const Navbar = styled.nav<{ scrolled: boolean }>`
  background: var(--v2-color-white);
  padding: ${(props) => (props.scrolled ? "16px 80px 0" : "32px 80px 0")};
  display: flex;
  position: sticky;
  top: 60px;
  z-index: 999;
  transition: all 0.3s ease;
`;

interface NavbarItemProps {
  active?: boolean;
}

export const NavbarItem = styled.div<NavbarItemProps>`
  margin-right: 20px;
  padding-bottom: 10px;
  cursor: pointer;
  color: ${(props) =>
    props.active ? "var(--v2-color-active)" : "var(--rv-grey-text)"};
  font-size: 16px;
  font-weight: ${(props) => (props.active ? "700" : "500")};
  border-bottom: ${(props) =>
    props.active ? "2px solid var(--v2-color-active)" : "none"};
`;

export const MainContent = styled.main`
  padding: 40px 200px 0px;

  @media (max-width: 1024px) {
   padding: 40px 40px 0px;
  }
`;

export const CardGroup = styled.div`
  background-color: var(--color-white);
  border-radius: 8px;
  padding: 24px;
`;



export const Card = styled.div`
  display: flex;
  img {
    border-radius: 8px;
    height: 143.3px;
    width: 216px;
  }
`;

export const ImageTitle = styled.div`
 padding-left: 16px;
 color: #132A60;
 font-size: 14px;
 font-style: normal;
 font-weight: 700;
 line-height: 150%;
 text-transform: capitalize;
`;

export const Label = styled.label`
  display: block;
  font-weight: bold;
  padding-top: 24px;
  padding-bottom: 8px;
`;

export const Section = styled.section`
  background-color: var(--color-background-primary);
  border-radius: 8px;
`;

export const TextBlock = styled.div`
  border-radius: 11px;
`;

export const TextWrapper = styled.div`
    display: flex;
    gap: 10px;
`

export const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 80px;
  background-color: var(--v2-grey-50);
  border-top: 2px solid var(--v2-color-dropdown-right);
  position: sticky;
  bottom: 0;
  z-index: 1000;
`;

export const FooterText = styled.div`
  font-size: 14px;
  color: var(--v2-color-gray-dark);
  margin-right: 20px;
`;

export const AttachmentWrapper = styled.div`
    padding: 35px 109px;
    align-items: center;
    justify-content: space-between;
    border: 1px dashed #bbbbbb;
    border-radius: 5px;
    height: 100px;
`

export const AttachmentContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const AttachmentTitle = styled.div`
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 150%;
padding-top: 7px;
`

export const BrowseLink = styled.div`
color: #1A6D93;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 150%;
` 

export const AttachmentDescription = styled.div`
color: #969DB2;
leading-trim: both;
text-edge: cap;
font-size: 9px;
font-style: normal;
font-weight: 400;
line-height: 150%;
` 

export const FileAttachmentWrapper = styled.div`
margin-top: 16px;
border-radius: 6px;
border: 1px solid #E4E9F0;
padding: 10px;
position: relative;
`;

export const RemoveFileButton = styled.div`
    position: absolute;
    right: 5px;
    top: 5px;
`;

export const FileAttachmentContent = styled.div`
display: flex;
` 

export const FileInfo = styled.div`
padding-left:  7px;
`

export const FileName = styled.div`
color: #132A60;
font-size: 10px;
font-style: normal;
font-weight: 500;
line-height: 150%
`

export const FileDetails = styled.div`
color: #969DB2;
leading-trim: both;
text-edge: cap;
font-size: 8px;
font-style: normal;
font-weight: 400;
line-height: 150%;
`

export const FileThumbnail = styled.div`
    padding: 5px;
    background: #EAEAEA;
    width: 25px;
    height: 25px;
    border-radius: 6px;
`;


export const UploadProgressWrapper = styled.div`
 margin-top: 10px;
 position: relative;
`;

export const Progress = styled.div`
  height: 5px;
  background-color: #e0e0df;
  border-radius: 4px;
  overflow: hidden;
  width: 433px;
  flex-shrink: 0;
  position: relative; 
`;

export const Filler = styled.div<{ progress: number; }>`
  height: 100%;
  width: ${({ progress }) => progress}%;
  background-color: #003F5C;
  transition: width 0.5s ease-in-out;
  border-radius: inherit;
  text-align: right;
`;

export const ProgressLabel = styled.div`
  color: #969db2;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  position: absolute;
  right: 0px; 
  top: -7px;
  white-space: nowrap; 
`;

export const ActionsWrapper = styled.div`
   padding-top: 16px;
`
export const ActionButtonGroup = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 8px;
`;


