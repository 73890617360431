import { format, subHours } from "date-fns";
import { Paperclip } from "phosphor-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { File } from "phosphor-react";
import { Avatar } from "../../../components/avatar/Avatar";
import { Button, ButtonGroup } from "../../../components/button/Button";
import { Tag } from "../../../components/tag/Tag";
import { TextInput } from "../../../components/textInput/TextInput";
import { Body } from "../../../components/typography/body/Body";
import { Heading } from "../../../components/typography/heading/Heading";
import { useProvider } from "../../../lib/hooks/useProvider";
import { Attachment, CarePlanNote } from "../../../lib/interfaces/carePlan";
import { PatientInfo, ProviderInfo } from "../../../lib/interfaces/user";
import styles from "./style.module.css";
import { useNotes } from "../../../lib/hooks/useNotes";
import _ from "lodash";

interface Log {
  timeStamp: string;
  authorStamp: string;
  provider: ProviderInfo;
}

export interface AddedAttachment {
  name: string;
  file: File;
}

interface AttachmentsRef {
  added: AddedAttachment[];
  deleted: Attachment[];
}

interface NoteEditFormProps {
  note?: CarePlanNote;
  patient: PatientInfo;
  onSubmit: () => void;
  onClose: () => void;
}

export const NoteEditForm = ({
  note,
  patient,
  onSubmit,
  onClose,
}: NoteEditFormProps) => {
  const [title, setTitle] = useState(note?.title || "");
  const [changesMade, setChangesMade] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [description, setDescription] = useState(note?.description || "");
  const [attachments, setAttachments] = useState<Attachment[]>(
    note?.attachments || [],
  );
  const [logs, setLogs] = useState<Log[]>([]);
  const [updNote, setUpdNote] = useState<CarePlanNote>();
  const [loadingAttachments, setLoadingAttachments] = useState<Attachment[]>(
    [],
  );
  const filePickerRef = useRef<HTMLInputElement>(null);
  const modifiedAttachments = useRef<AttachmentsRef>({
    added: [],
    deleted: [],
  });
  const { getProvider } = useProvider();
  const { updateCarePlanNote, createCarePlanNote, getCarePlanNote } =
    useNotes();

  useEffect(() => {
    if (note && note.history.length > 0) {
      Promise.all(
        note?.history.map(async (log, i): Promise<Log> => {
          const date = subHours(
            new Date(log.modifiedOn.split(" ").join("T")),
            7,
          );
          const author = await getProvider(log.modifiedBy);
          const timeStamp = `${format(date, "MMMM dd, yyyy")} at ${format(
            date,
            "h:mm a",
          )}`;
          const authorStamp = `${i === 0 ? "Created" : "Edited"} by ${
            author.name
          }`;
          return { timeStamp, authorStamp, provider: author };
        }),
      ).then(async (res) => {
        //note.history doesn't include the current note's modified date, so we need to append it to the logs
        const date = subHours(
          new Date(note.modifiedOn.split(" ").join("T")),
          7,
        );
        const author = await getProvider(note.modifiedBy);
        const timeStamp = `${format(date, "MMMM dd, yyyy")} at ${format(
          date,
          "h:mm a",
        )}`;
        const authorStamp = `Edited by ${author.name}`;
        res.push({ timeStamp, authorStamp, provider: author });
        setLogs(res);
      });
    } else if (note) {
      getProvider(note.modifiedBy).then((_provider) => {
        const date = subHours(
          new Date(note.modifiedOn.split(" ").join("T")),
          7,
        );
        const timeStamp = `${format(date, "MMMM dd, yyyy")} at ${format(
          date,
          "h:mm a",
        )}`;
        const authorStamp = `Created by ${_provider.name}`;
        setLogs([{ timeStamp, authorStamp, provider: _provider }]);
      });
    }
  }, [note]);

  useEffect(
    () => () => setChangesMade(true),
    [description, attachments, title],
  );

  useEffect(() => {
    if (loadingAttachments.length > 0 && note) {
      getCarePlanNote(patient.id, note.id).then((_carePlanNote) =>
        setUpdNote(_carePlanNote[0]),
      );
    }
  }, [loadingAttachments]);

  useEffect(() => {
    if (updNote) {
      if (updNote.attachments.length > 0) {
        loadingAttachments.map((loadingAttachment) => {
          updNote.attachments.map((updNoteAttachment) => {
            if (updNoteAttachment.name === loadingAttachment.name) {
              window.open(updNoteAttachment.url);
              const updLoadingAttachments: Attachment[] = JSON.parse(
                JSON.stringify(loadingAttachments),
              );
              const attachmentToRemove =
                updLoadingAttachments.indexOf(loadingAttachment);
              updLoadingAttachments.splice(attachmentToRemove, 1);
              setLoadingAttachments(updLoadingAttachments);
            }
          });
        });
      }
    }
  }, [updNote]);

  const handleDeleteAttachment = (attachment: Attachment) => {
    if (
      _.find(
        modifiedAttachments.current?.added,
        (_attachment) => _attachment.name === attachment.name,
      )
    )
      modifiedAttachments.current.added =
        modifiedAttachments.current.added.filter(
          (_attachment) => _attachment.name !== attachment.name,
        );
    else modifiedAttachments.current.deleted.push(attachment);

    setAttachments(
      attachments.filter((_attachment) => _attachment.name !== attachment.name),
    );
  };

  const handleAddAttachmentClick = () => {
    filePickerRef.current?.click();
  };

  const handleFilePick = async (_files: FileList | null) => {
    const files = Object.values(_files || {}).filter(
      (file) =>
        !attachments.map((attachment) => attachment.name).includes(file.name),
    );

    if (files.length > 0) {
      modifiedAttachments.current?.added.push(
        ...files.map((file) => ({ name: file.name, file })),
      );
      setAttachments([
        ...attachments,
        ...files.map((file) => ({
          name: file.name,
          url: file.name, // here used only for react key prop for rendering
        })),
      ]);
    }
  };

  const isFormValid = useMemo(() => {
    return title && description;
  }, [title, description]);

  const handleSaveClick = async () => {
    const onDone = () => {
      setUploading(false);
      onSubmit();
      onClose();
    };

    setUploading(true);
    if (note) {
      const updatedNote = {
        id: note.id,
        userId: patient.id,
        description,
        title,
      };
      await updateCarePlanNote(
        updatedNote,
        modifiedAttachments.current.added,
        modifiedAttachments.current.deleted,
        onDone,
      );
    } else {
      const newNote = {
        userId: patient.id,
        description,
        title,
      };
      await createCarePlanNote(
        newNote,
        modifiedAttachments.current.added,
        onDone,
      );
    }
  };

  return (
    <div className={styles.noteEditForm}>
      <div className={styles.leftColumn}>
        <Heading className={styles.titleLabel} type="03">
          Title
        </Heading>
        <TextInput className={styles.title} value={title} onChange={setTitle} />
        <Heading className={styles.descriptionLabel} type="03">
          Progress Description
        </Heading>
        <TextInput
          className={styles.descriptionInput}
          value={description}
          onChange={setDescription}
          multiline
          minRows={6}
          maxRows={4}
        />
        <Body size="md" className={styles.attachmentsLabel}>
          Attachments (optional)
        </Body>
        <Body
          size="sm"
          color="secondary"
          className={styles.attachmentsSubtext1}
        >
          {`Add files that relate to this patient's progress description note. You can always go back and edit this later.`}
        </Body>
        <Body
          size={"sm"}
          color={"secondary"}
          weight="bold"
          className={styles.attachmentsSubtext2}
        >
          The maximum number of attachments is 2.
        </Body>
        {attachments.length > 0 && (
          <div className={styles.attachments}>
            {attachments.map((attachment) => (
              // <a href={attachment.url} target="_blank" rel="noopener noreferrer" className={styles.newTab}>
              <Tag
                key={attachment.url}
                content={attachment.name}
                type="black"
                size="lg"
                Icon={File}
                onDelete={() => handleDeleteAttachment(attachment)}
                onClick={() =>
                  setLoadingAttachments([...loadingAttachments, attachment])
                }
                loading={loadingAttachments.includes(attachment)}
              />
              // </a>
            ))}
          </div>
        )}
        <Button
          disabled={attachments.length > 1}
          label="Add Attachment"
          type="secondary-gray"
          onClick={handleAddAttachmentClick}
          Icon={Paperclip}
        />
        <input
          ref={filePickerRef}
          type="file"
          accept="image/*,.pdf"
          style={{ display: "none" }}
          onChange={(e) => handleFilePick(e.target.files)}
        />
        <div className={styles.separator} />
        <ButtonGroup align="start">
          <Button label="Cancel" type="secondary-gray" onClick={onClose} />
          <Button
            label="Save and Close"
            disabled={!changesMade || !isFormValid}
            loading={uploading}
            onClick={handleSaveClick}
          />
        </ButtonGroup>
      </div>

      <div className={styles.rightColumn}>
        <div className={styles.authorLogs}>
          <Body weight="bold" size="md">
            Author Log
          </Body>
          {logs.length > 0 ? (
            <div className={styles.logs}>
              {logs.map((log) => (
                <div className={styles.log}>
                  <Body size="sm">{log.timeStamp}</Body>
                  <div className={styles.logAuthor}>
                    <Avatar size="xxs" user={log.provider} />
                    <Body
                      className={styles.authorName}
                      size="xs"
                      color="secondary"
                    >
                      {log.authorStamp}
                    </Body>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <Body size="sm" color="secondary">
              {"No edits have been made to this note"}
            </Body>
          )}
        </div>
      </div>
    </div>
  );
};
