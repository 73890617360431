import { Button } from "../../button/Button";
import { ChevronRight } from "../../customIcons/CustomIcons";
import { PlusIconGray } from "../../customIcons/CustomIcons";
import { CarePlanStatus } from "../../carePlanStatus/CarePlanStatus";
import { CarePlanPillarProps } from "../../../lib/interfaces/carePlan";
import {
  Cards,
  Icon,
  PillarInfoContainer,
  PillarTypeLabel,
  PillarInfoUpdate,
  Info,
  PillarDetailContainer,
  Blank,
  RightArrowIcon,
} from "./Styled";
import { useCarePlan } from "../../../lib/hooks/useCarePlan";
import { useContext } from "react";
import { BaseContext } from "../../../lib/context/context";
import { careplanFormatDateTime } from "../../../lib/util/date";

export const Pillar = ({
  pillarDetail,
  isPillarInfoAvailable,
  onClick,
  setIsPillarPageShow,
}: CarePlanPillarProps) => {
  const { allProviders, setPillarData } = useContext(BaseContext);

  function getProviderNameById(providerId: string) {
    return (
      allProviders?.find((provider) => provider?.providerId === providerId)
        ?.name ?? ""
    );
  }
  return (
    <Cards>
      {!isPillarInfoAvailable && (
        <Icon>
          <img
            src={
              isPillarInfoAvailable
                ? pillarDetail?.icons?.colorIcon
                : pillarDetail?.icons?.grayIcon
            }
            alt="icon"
          />
        </Icon>
      )}
      <PillarInfoContainer isPillarInfoAvailable={isPillarInfoAvailable}>
        {isPillarInfoAvailable ? (
          <>
            <Icon>
              <img
                src={
                  isPillarInfoAvailable
                    ? pillarDetail?.icons?.colorIcon
                    : pillarDetail?.icons?.grayIcon
                }
                alt="icon"
              />
            </Icon>
            <PillarDetailContainer>
              <PillarTypeLabel>
                {pillarDetail?.pillarName && (
                  <span>{pillarDetail?.pillarName}</span>
                )}
                <span>
                  {pillarDetail?.contentData?.content?.patientGroupType?.name}
                </span>
              </PillarTypeLabel>

              {pillarDetail?.contentData?.updatedAt && (
                <PillarInfoUpdate>
                  <span>
                    Updated{" "}
                    {careplanFormatDateTime(
                      pillarDetail?.contentData?.updatedAt
                    )?.toLowerCase()}
                  </span>
                  {pillarDetail?.contentData?.updatedBy && (
                    <span>
                      {getProviderNameById(
                        pillarDetail?.contentData?.updatedBy
                      )}
                    </span>
                  )}
                </PillarInfoUpdate>
              )}
              {pillarDetail?.contentData?.status !== undefined && (
                <CarePlanStatus status={pillarDetail?.contentData?.status} />
              )}

              {isPillarInfoAvailable && setIsPillarPageShow && (
                <RightArrowIcon
                  onClick={() => {
                    setIsPillarPageShow(true);
                    const data = {
                      id: pillarDetail?.id,
                      pillarName: pillarDetail?.pillarName,
                      icons: pillarDetail?.icons,
                      contentData: pillarDetail?.contentData,
                    };
                    setPillarData(data);
                  }}
                >
                  <ChevronRight />
                </RightArrowIcon>
              )}
            </PillarDetailContainer>
          </>
        ) : (
          <>
            <Info>
              <Button
                size="small"
                type="transparent"
                label={`Add ${pillarDetail?.pillarName}`}
                iconPosition="left"
                Icon={PlusIconGray}
                onClick={onClick}
              />
            </Info>
            <Blank />
          </>
        )}
      </PillarInfoContainer>
    </Cards>
  );
};
