import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Heading } from "../../components/typography/heading/Heading";
import { Button, ButtonGroup } from "../../components/button/Button";
import { Modal } from "../../components/modal/Modal";
import { PatientOption } from "../../lib/interfaces/input";
import { SearchOption } from "../../lib/interfaces/listOfPatients";
import {
  PatientInfo,
  ProviderInfo,
  defaultPatient,
} from "../../lib/interfaces/user";
import { Drawer } from "../../components/drawer/Drawer";
import { NewAppointment } from "../../containers/schedule/newAppointment/NewAppointment";
import {
  FunnelSimple,
  NotePencil,
  CaretLeft,
  CaretRight,
} from "phosphor-react";
import styles from "./style.module.css";
import { AppointmentList } from "../../containers/schedule/appointmentList/AppointmentList";
import { addDays, format, startOfISOWeek, subDays, isSameWeek } from "date-fns";
import { AppointmentDetail } from "../../containers/schedule/appointmentDetail/AppointmentDetail";
import { useLocation } from "react-router-dom";
import { FiltersForm } from "../../containers/schedule/filtersForm/FiltersForm";
import { AlertContext, BaseContext } from "../../lib/context/context";
import { defaultProvider } from "../../lib/interfaces/user";
import _ from "lodash";
import PatientSearch from "../patient/PatientSearch";
import { usePatient } from "../../lib/hooks/usePatient";

export interface LocationState {
  visitId: string;
  patient: PatientInfo;
  postCall?: boolean;
}

export const Schedule = () => {
  const { pushAlert } = useContext(AlertContext);
  const { userInfo } = useContext(BaseContext);
  const { getPatient } = usePatient();

  const [viewNewApptDrawer, setViewNewApptDrawer] = useState(false);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [resetLoading, setResetLoading] = useState<boolean>(false);
  const [apptDetailVisible, setApptDetailVisible] = useState<boolean>(false);
  const [refreshNeeded, setRefreshNeeded] = useState<boolean>(true);
  const [selectedSearchOption, setSelectedSearchOption] =
    useState<SearchOption>({ patient: { name: "", value: "" } });
  const [signedInUser, setSignedInUser] = useState<ProviderInfo>(
    userInfo || defaultProvider,
  );
  const [assignedToMe, setAssignedToMe] = useState(false);
  const [assignedToMeTemp, setAssignedToMeTemp] = useState(false);
  const [apptWithMe, setApptWithMe] = useState(false);
  const [apptWithMeTemp, setApptWithMeTemp] = useState(false);
  const [patient, setPatient] = useState<PatientInfo>(defaultPatient);
  const location = useLocation<LocationState>();

  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }

  const urlQuery = useQuery();

  const appointmentListActionRef = useRef<any>(null);

  const autoShowVisitId = useMemo(() => {
    return urlQuery.get("visitId") || location?.state?.visitId || "";
  }, [urlQuery, location]);

  const autoShowVisitPatientId = useMemo(() => {
    return urlQuery.get("patientId") || location?.state?.patient?.id || "";
  }, [urlQuery, location]);

  useEffect(() => {
    const patientId =
      urlQuery.get("patientId") || location?.state?.patient?.id || "";
    if (patientId) {
      getPatient(patientId).then(setPatient);
    }
  }, [urlQuery, location]);

  const directShowAppointment = useMemo(() => {
    return autoShowVisitId && autoShowVisitPatientId;
  }, [autoShowVisitId, autoShowVisitPatientId]);

  useEffect(() => {
    if (userInfo) setSignedInUser(userInfo);
  }, [userInfo]);

  const onDrawerClose = () => {
    setApptDetailVisible(false);
    //clear location state
    window.history.replaceState(null, "");
  };

  useEffect(() => {
    setApptDetailVisible(true);
  }, [location]);

  const handleFilterButtonClick = () => {
    setShowFiltersModal(true);
  };

  const handleFilterApply = () => {
    setAssignedToMe(assignedToMeTemp);
    setApptWithMe(apptWithMeTemp);
    setShowFiltersModal(false);
  };

  const handleFilterClearAll = () => {
    setAssignedToMeTemp(false);
    setApptWithMeTemp(false);
    setAssignedToMe(false);
    setApptWithMe(false);
    setShowFiltersModal(false);
  };

  const handleFilterExit = () => {
    setShowFiltersModal(false);
    setAssignedToMeTemp(assignedToMe);
    setApptWithMeTemp(apptWithMe);
  };

  const handlePatientsChange = (
    selectedPatient: PatientOption | null | undefined,
  ) => {
    if (selectedPatient) {
      setSelectedSearchOption({
        patient: selectedPatient,
      });
      setRefreshNeeded(!refreshNeeded);
    }
  };

  const handleAssignedToMeToggle = () => {
    setAssignedToMeTemp(!assignedToMeTemp);
  };

  const handleAppointmentsWithMeToggle = () => {
    setApptWithMeTemp(!apptWithMeTemp);
  };

  const handleNewAppointmentExit = () => {
    setViewNewApptDrawer(false);
  };

  const handleNewAppointmentClick = () => {
    setViewNewApptDrawer(true);
  };

  //weekDisplayed refers the Monday date of the week displayed on page
  const [weekDisplayed, setWeekDisplayed] = useState<Date>(
    startOfISOWeek(new Date()),
  );

  const [monthYr, setMonthYr] = useState<string>(
    format(weekDisplayed, "MMMM yyyy"),
  );

  useEffect(() => {
    setMonthYr(format(weekDisplayed, "MMMM yyyy"));
    setResetLoading(false);
  }, [weekDisplayed]);

  const handleBackCaretClick = () => {
    const result = subDays(weekDisplayed, 7);
    setWeekDisplayed(result);
    setResetLoading(true);
  };

  const handleForwardCaretClick = () => {
    const result = addDays(weekDisplayed, 7);
    setWeekDisplayed(result);
    setResetLoading(true);
  };

  const handleThisWeekClick = () => {
    setWeekDisplayed(startOfISOWeek(new Date()));
    setResetLoading(true);
  };

  const handleCancelAppointment = (visitId: string) => {
    // filter out appt with visitId - avoid refesh/refetch
    setApptDetailVisible(false);
    appointmentListActionRef.current();
  };

  const handleNewAppointmentCreated = (bookAnother?: boolean) => {
    if (!bookAnother) {
      setViewNewApptDrawer(false);
      pushAlert("Appointment successfully created!", "success");
    }
    appointmentListActionRef.current(weekDisplayed);
  };

  const checkSelectedWeekThisWeek = () => {
    return isSameWeek(weekDisplayed, Date.now());
  };

  return (
    <>
      <div className={styles.page}>
        <div className={styles.header}>
          <Heading type="02">Schedule</Heading>
          <div className={styles.searchAndFilterAndAppt}>
            <div className={styles.searchAndFilter}>
              <PatientSearch
                placeholder="Search"
                handlePatientsChange={handlePatientsChange}
                value={selectedSearchOption.patient}
                isPatientSearchPage={false}
                className={styles.input}
              />
             <Button
                onClick={() => {
                  handleFilterButtonClick();
                }}
                Icon={FunnelSimple}
                label={apptWithMe || assignedToMe ? "Filters (1)" : "Filter"}
                type="secondary-gray"
              />
              <Button
                onClick={() => {
                  handleNewAppointmentClick();
                }}
                Icon={NotePencil}
                label={"New Appointment"}
                type="primary"
              />
             </div>
          </div>

          <div className={styles.dateButtons}>
            <div className={styles.dateHeading}>
              <Heading type={"02"}>{monthYr}</Heading>
            </div>
            <ButtonGroup>
              <Button
                onClick={() => handleBackCaretClick()}
                Icon={CaretLeft}
                size={"small"}
                type={"secondary-gray"}
              />
              <Button
                onClick={() => handleThisWeekClick()}
                disabled={checkSelectedWeekThisWeek()}
                label="This Week"
                size={"small"}
                type={"secondary-gray"}
              />
              <Button
                onClick={() => handleForwardCaretClick()}
                Icon={CaretRight}
                size={"small"}
                type={"secondary-gray"}
              />
            </ButtonGroup>
          </div>
        </div>
        <div className={styles.listContainer}>
          <AppointmentList
            actionRef={appointmentListActionRef}
            signedInUser={signedInUser}
            weekDisplayed={weekDisplayed}
            resetLoading={resetLoading}
            searchOption={selectedSearchOption}
            assignedToMe={assignedToMe}
            apptWithMe={apptWithMe}
          />
        </div>
      </div>

      <Drawer
        title={"New Appointment"}
        onClose={() => {
          handleNewAppointmentExit();
        }}
        visible={viewNewApptDrawer}
        className={styles.drawer}
      >
        <NewAppointment onNewAppointmentBooked={handleNewAppointmentCreated} />
      </Drawer>

      {directShowAppointment && (
        <Drawer
          visible={apptDetailVisible}
          onClose={onDrawerClose}
          title="Appointment Detail"
        >
          <div style={{ overflow: "auto", maxHeight: "100%" }}>
            <AppointmentDetail
              patient={patient}
              visitId={autoShowVisitId}
              postCall={location?.state?.postCall}
            />
          </div>
        </Drawer>
      )}

      <Modal
        dismissable={true}
        visible={showFiltersModal}
        onCloseModal={() => handleFilterExit()}
        title="Filter"
      >
        <FiltersForm
          signedInUser={signedInUser}
          assignedToMe={assignedToMeTemp}
          appointmentsWithMe={apptWithMeTemp}
          handleAppointmentsWithMeToggle={handleAppointmentsWithMeToggle}
          handleAssignedToMeToggle={handleAssignedToMeToggle}
          handleFilterApply={handleFilterApply}
          handleFilterClearAll={handleFilterClearAll}
        />
      </Modal>
    </>
  );
};
