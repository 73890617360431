import styles from "./style.module.css";
import { CarePlanPillarDetail } from "../../../components/carePlan/CarePlanPillarDetails";
import { PillarCarePlanHeader } from "./PillarCarePlanHeader";
import { PillarCarePlanFooter } from "./PillarCarePlanFooter";
import { PublishModalProps } from "../../../lib/interfaces/carePlan";

export const PillarCarePlan = (carePlanDetail: PublishModalProps) => {
  const {
    title,
    needType,
    status,
    updatedAt,
    updatedBy,
    patient,
    onEditClick,
  } = carePlanDetail;
  return (
    <div className={styles.container}>
      <div>
        <PillarCarePlanHeader patient={patient} title={title} />
        <div className={styles.infoContainer}>
          <CarePlanPillarDetail
            pillarDetail={{
              title,
              needType,
              status,
              updatedAt,
              updatedBy,
              onEditClick,
            }}
          />
        </div>
      </div>
      <PillarCarePlanFooter carePlanDetail={carePlanDetail} />
    </div>
  );
};
