import { PatientInfo, ProviderInfo } from "../../../lib/interfaces/user";
import { Visit } from "../../../lib/interfaces/visits";
import { useVisits } from "../../../lib/hooks/useVisits";
import { IconProps } from "phosphor-react";
import {
  format,
  isToday,
  startOfDay,
  isWithinInterval,
  startOfToday,
  addDays,
  isTomorrow,
} from "date-fns";
import { Drawer } from "../../../components/drawer/Drawer";
import { CareTeamDetail } from "../careTeamDetail/CareTeamDetail";
import { useHistory } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { PatientHeader } from "../../../components/patientHeader/PatientHeader";
import { useCareTeam } from "../../../lib/hooks/useCareTeam";
import styles from "./style.module.css";
import { BaseContext } from "../../../lib/context/context";
import { VisitsData } from "../../../lib/apis/visits";

export interface HeaderProps {
  patient: PatientInfo;
  showPatientsButton?: boolean;
  primaryAction?: (_?: any) => void;
  primaryActionLabel?: string;
  primaryActionIcon?: React.ForwardRefExoticComponent<
    IconProps & React.RefAttributes<SVGSVGElement>
  >;
  activeTab: string;
  onActiveTabChange: (route: string) => void;
  allVisits?: VisitsData;
}

export const Header = ({
  patient,
  showPatientsButton = true,
  primaryAction,
  primaryActionLabel,
  primaryActionIcon,
  activeTab,
  onActiveTabChange,
  allVisits,
}: HeaderProps) => {
  const { getUpcomingAndLastVisit } = useVisits();
  const { getCareTeam } = useCareTeam();
  const { allProviders } = useContext(BaseContext);

  const [showCareTeamDetail, setShowCareTeamDetail] = useState(false);
  const [careTeam, setCareTeam] = useState<ProviderInfo[]>();
  const [patientNextAppointment, setPatientNextAppointment] = useState<Visit>();
  const [patientLastAppointment, setPatientLastAppointment] = useState<Visit>();
  const [visitRes, setVisitRes] = useState<string>();

  useEffect(() => {
    if (allVisits) {
      getUpcomingAndLastVisit(allVisits).then((res) => {
        setPatientNextAppointment(res.upcomingVisit);
        setPatientLastAppointment(res.pastVisit);
        setVisitRes(res.status);
      });
    }
  }, [allVisits]);

  useEffect(() => {
    if (allProviders)
      getCareTeam(patient.id, allProviders).then((res) => {
        setCareTeam(res);
      });
  }, [allProviders]);

  const getAppointmentText = () => {
    if (visitRes && visitRes === "error") {
      return "Failed to get patient's information";
    }
    const appt =
      patientNextAppointment &&
      patientNextAppointment.visitId !== "" &&
      patientNextAppointment.dateTime !== ""
        ? new Date(patientNextAppointment.dateTime)
        : undefined;
    //if the appointment is today
    if (appt && isToday(startOfDay(appt))) {
      return `Next Appointment: Today at ${format(appt, "h:mm a")}`;
      //if the appointment is tomorrow
    } else if (appt && isTomorrow(startOfDay(appt))) {
      return `Next Appointment: Tomorrow at ${format(appt, "h:mm a")}`;
      //if the appointment is within a week from today
    } else if (
      appt &&
      isWithinInterval(startOfDay(appt), {
        start: startOfToday(),
        end: addDays(startOfToday(), 7),
      })
    ) {
      return `Next Appointment: ${format(appt, "M/d/yy")}`;
      //if they have a last appointment and no upcoming appointment within a week
    } else if (
      patientLastAppointment &&
      patientLastAppointment.visitId !== "" &&
      patientLastAppointment.dateTime !== ""
    ) {
      return `Last Appointment: ${format(
        new Date(patientLastAppointment.dateTime.split(" ").join("T")),
        "M/d/yy"
      )}`;
    } else return undefined;
  };

  const appointmentText = getAppointmentText();

  const titleOverride: JSX.Element | undefined = appointmentText ? (
    <>
      <div style={{ display: "flex" }}>
        {visitRes !== "error" ? (
          <>
            {appointmentText.charAt(0) === "N"
              ? "Next Appointment: "
              : "Last Appointment: "}
            <div className={styles.boldSubText}>
              {" " + appointmentText.substring(17)}
            </div>
          </>
        ) : (
          <>
            {"Last Appointment: "}
            <div className={styles.boldSubText}>{appointmentText}</div>
          </>
        )}
      </div>
    </>
  ) : (
    <>Patient</>
  );

  const onAvatarClick = () => {
    setShowCareTeamDetail(true); /*change to false when drawer is closed*/
  };

  const onDrawerClose = () => {
    setShowCareTeamDetail(false);
  };

  const updateDisplayedCareTeam = (updatedCareTeam: ProviderInfo[]) => {
    setCareTeam(updatedCareTeam);
  };

  const history = useHistory();

  const onPatientsClick = () => {
    history.push("/patientsearch");
  };

  return (
    <div>
      <PatientHeader
        patient={patient}
        showPatientsButton={showPatientsButton}
        primaryAction={primaryAction}
        primaryActionLabel={primaryActionLabel}
        primaryActionIcon={primaryActionIcon}
        onAvatarClick={onAvatarClick}
        onPatientsClick={onPatientsClick}
        careTeam={careTeam}
        activeTab={activeTab}
        titleOverride={titleOverride}
        onActiveTabChange={onActiveTabChange}
        visit={patientNextAppointment}
      />
      <div>
        <Drawer
          visible={showCareTeamDetail}
          onClose={onDrawerClose}
          title="Care Team"
          usePortal={true}
          headerClassName={styles.drawerHeader}
        >
          {careTeam && (
            <div style={{ overflow: "auto", maxHeight: "100%" }}>
              <CareTeamDetail
                patientId={patient.id}
                careTeam={careTeam ? careTeam : []}
                updateDisplayedCareTeam={updateDisplayedCareTeam}
              />
            </div>
          )}
        </Drawer>
      </div>
    </div>
  );
};
