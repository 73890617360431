import { StatusChanged, StatusPublished, StatusDraft } from "./Styled";
import React from "react";

export const CarePlanStatus: React.FC<{ status: string }> = ({ status }) => {
  const getPillarStatus = (): JSX.Element => {
    const normalizedStatus = status.toLowerCase();

    switch (normalizedStatus) {
      case "changed":
        return <StatusChanged>{status}</StatusChanged>;
      case "published":
        return <StatusPublished>{status}</StatusPublished>;
      default:
        return <StatusDraft>{status}</StatusDraft>;
    }
  };

  return getPillarStatus();
};
