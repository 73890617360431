import styled from "styled-components";

export const Box = styled.div`
  border-radius: 8px;
  margin-top: 32px;
  border: 1px solid var(--color-primary-700);
  background-color: var(--color-primary-25);
  padding: 16px;
`;

export const Row = styled.div`
  display: flex;
  gap: 12px;
  align-self: stretch;
  align-items: flex-start;
`;

export const CarePlanIcon = styled.div`
  width: 25px;
`;

export const CarePlanDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 50%;
`;

export const CarePlanItem = styled.div<{ isPatientInfo?: boolean }>`
  display: flex;
  flex-direction: column;

  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
  }

  strong {
    font-size: 16px;
    font-weight: 700;
    line-height: 150%;
  }

  ${({ isPatientInfo }) =>
    isPatientInfo &&
    `
    width: calc(50% - 25px);
  `}
`;
