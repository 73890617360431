import styled from "styled-components";

interface ILayoutProps {
  isPillarInfoAvailable: boolean;
}

export const Cards = styled.div`
  display: flex;
  align-items: center;
  padding: 25px 80px 0px 80px;

  guide {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #bbbbbb;
    border-radius: 5px;
    width: 100vw;
    height: 100px;
  }
`;

export const Icon = styled.div`
  flex: 0 0 auto;
  height: 100px;
  line-height: 1px;
  background-color: #ddd;
  border-radius: 8px 0 0 8px;
  img {
    border-radius: 7px 0px 0px 7px;
  }
`;

export const PillarTypeLabel = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  width: 200px;

  span:nth-child(1) {
    padding: 0px 0px 4px 0px;
    margin: 0px;
    font-size: 23px;
    letter-spacing: -0.15px;
    font-weight: 700;
  }

  span:nth-child(2) {
    margin: 0px;
    font-size: 16px;
    font-weight: 700;
  }
`;

export const PillarInfoUpdate = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: start;
  width: 200px;

  span {
    font-size: 14px;
    font-weight: 400;
    padding: 2.5px 0px;
  }

  span:first-child {
    color: var(--color-gray-900);
  }

  span:nth-child(2) {
    color: var(--rv-grey-text);
  }
`;

export const RightArrowIcon =  styled.div`
cursor: pointer;
`

export const Info = styled.div`
  text-align: center;
  cursor: pointer;

  div {
    font-size: 16px;
    font-weight: 400;
  }
`;

export const Blank = styled.div`
  width: 120px;
`;

export const PillarInfoContainer = styled.div<ILayoutProps>`
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: center;

  ${({ isPillarInfoAvailable }) => {
    if (!isPillarInfoAvailable) {
      return `
            flex: 1;
            position: relative;
            overflow: hidden;
            padding: 0px 24px;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;

            :before {
                content: "";
                position: absolute;
                border: 5px dashed #ddd;
                border-left: transparent;
                top: -4px;
                bottom: -4px;
                left: -4px;
                right: -4px;
                border-top-right-radius: 12px;
                border-bottom-right-radius: 13px;
            }
        `;
    } else if (isPillarInfoAvailable) {
      return `
            justify-content: space-between;
            border: 1px solid #bbbbbb;
            border-radius: 8px;
            width: 100%;
            height: 100px;
        `;
    }
  }}
`;

export const PillarDetailContainer = styled.div`
  display: flex;
  height: 100px;
  width: 100%;
  padding: 0px 24px;
  align-items: center;
  justify-content: space-between;
`;
