import { datadogLogs } from "@datadog/browser-logs";
import { trace, context, SpanKind, ROOT_CONTEXT } from '@opentelemetry/api';

const logger = datadogLogs.logger;

// Function to start a span and get trace and span IDs
const loggedInTrace = () => {
  const tracer = trace.getTracer('compass-provider');

  // Use startActiveSpan to create and activate a span
  return tracer.startActiveSpan('myComponentSpan', { kind: SpanKind.INTERNAL }, (span) => {
    // Retrieve the traceId and spanId
    const { traceId, spanId } = span.spanContext();
  // Convert traceId and spanId as per the requirement
    const traceIdEnd = traceId.slice(traceId.length / 2);
    const formattedTraceId = BigInt(`0x${traceIdEnd}`).toString();
    const formattedSpanId = BigInt(`0x${spanId}`).toString();
    // Return the trace and span IDs
    return { trace_id: formattedTraceId, span_id: formattedSpanId };
  });
};

export const logDebug = (message: string, context: object = {}) => {
  const data = loggedInTrace()
  logger.debug(message, {...context, ...data});
};

export const logInfo = (message: string, context: object = {}) => {
  const data = loggedInTrace()
  logger.info(message, {...context, ...data});
};

export const logWarn = (message: string, context: object = {}) => {
  const data = loggedInTrace()
  logger.warn(message, {...context, ...data});
};

export const logError = (
  message: string,
  context: object = {},
  error: Error,
) => {
  const data = loggedInTrace()
  logger.error(message, {...context, error, ...data});
};
