import styles from "./style.module.css";
import { Button } from "../../../components/button/Button";
import clsx from "clsx";
import { PublishModalProps } from "../../../lib/interfaces/carePlan";
import { Modal } from "../../../components/modal/Modal";
import PublishUnpublishCarePlan from "./PublishUnpublishCarePlan";
import { useState } from "react";

export enum PublishModalTypes {
  Draft = "Draft",
  Published = "Published",
  Unpublish = "Unpublish",
  Changed = "Changed",
}

export interface publishModalProps {
  isVisible: boolean;
  modalType?: PublishModalTypes;
}

const initalModalValues: publishModalProps = {
  isVisible: false,
};

export const PillarCarePlanFooter = ({
  carePlanDetail,
}: {
  carePlanDetail: PublishModalProps;
}) => {
  const [publishModal, setPublishModal] =
    useState<publishModalProps>(initalModalValues);

  const handleOpenModal = (type: PublishModalTypes) => {
    setPublishModal({ isVisible: true, modalType: type });
  };

  const handleCloseModal = () => {
    setPublishModal(initalModalValues);
  };

  return (
    <>
      <div className={styles.footer}>
        <div className={styles.buttons}>
          <Button
            onClick={() => handleOpenModal(PublishModalTypes.Unpublish)}
            label="Unpublish..."
            type="secondary-gray"
            className={clsx(
              carePlanDetail.status === PublishModalTypes.Draft &&
                styles.buttonDeactivate
            )}
            disabled={carePlanDetail.status === PublishModalTypes.Draft}
          />
          <Button
            onClick={() => handleOpenModal(PublishModalTypes.Published)}
            label="Publish..."
            className={clsx(
              carePlanDetail.status === PublishModalTypes.Published &&
                styles.buttonDeactivate
            )}
            disabled={carePlanDetail.status === PublishModalTypes.Published}
          />
        </div>
      </div>
      {publishModal.isVisible && (
        <Modal
          visible={publishModal.isVisible}
          title={
            publishModal.modalType === PublishModalTypes.Published
              ? "Publish Care Plan"
              : "Unpublish Care Plan"
          }
          dismissable={false}
          onCloseModal={handleCloseModal}
          className={styles.publishModal}
        >
          <PublishUnpublishCarePlan
            modalType={publishModal.modalType}
            onClose={handleCloseModal}
            carePlanDetail={carePlanDetail}
          />
        </Modal>
      )}
    </>
  );
};
