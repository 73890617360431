import styled from "styled-components";

export const PillarInfoContainer = styled.div`
  display: flex;
  height: 100px;
  border: 1px solid var(--v2-color-dropdown-right);
  border-radius: 8px;
  padding: 0px 24px;
  justify-content: space-between;
  align-items: center;
`;

export const PillarTypeLabel = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  width: 250px;

  span:nth-child(1) {
    padding: 0px 0px 4px 0px;
    margin: 0px;
    font-size: 23px;
    letter-spacing: -0.15px;
    font-weight: 700;
  }

  span:nth-child(2) {
    margin: 0px;
    font-size: 16px;
    font-weight: 700;
  }
`;

export const PillarInfoUpdate = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: start;
  width: 220px;

  span {
    font-size: 14px;
    font-weight: 400;
    padding: 2.5px 0px;
  }

  span:first-child {
    color: var(--color-gray-900);
  }

  span:nth-child(2) {
    color: var(--rv-grey-text);
  }
`;
