import { MouseEventHandler, ReactNode } from "react";
import { Users, CalendarBlank, Chat, BellSimple, User, SquaresFour } from "phosphor-react";
import { UserInfo } from "../../lib/interfaces/user";

import { StyledNavAvatar, StyledNavButton } from "./Styled";

type ButtonTypes =
  | "calendar"
  | "messages"
  | "patient"
  | "patientsearch"
  | "notifications"
  | "avatar"
  | "videochat"
  | "careplanmanager";

// Menu Button
interface MenuButtonProps {
  onClick?: MouseEventHandler;
  type: ButtonTypes;
  badge?: boolean;
  active?: boolean;
  user?: UserInfo;
  children?: ReactNode;
}

export const MenuButton = ({
  onClick,
  type,
  badge = false,
  active,
  children,
}: MenuButtonProps) => {
  let icon: JSX.Element = <></>;
  let toolTipText = "";
  switch (type) {
    case "calendar":
      icon = <CalendarBlank size={28} color={"white"} />;
      toolTipText = "Scheduling";
      break;
    case "messages":
      icon = <Chat size={28} color={"white"} />;
      toolTipText = "Messaging";
      break;
    case "patient":
      icon = <Users size={28} color={"white"} />;
      toolTipText = "Patients";
      break;
    case "patientsearch":
      icon = <Users size={28} color={"white"} />;
      toolTipText = "Patients";
      break;
    case "careplanmanager":
      icon = <SquaresFour size={28} color={"white"} />
      toolTipText="Care Plan Manager";
      break;
    case "notifications":
      icon = <BellSimple size={28} color={"white"} />;
      toolTipText = "Notifications";
      break;
    case "videochat":
      icon = <User size={28} color={"white"} />;
      toolTipText = "Toggle Meeting Roster";
      break;
  }

  if (type === "avatar") {
    return (
      <StyledNavAvatar
        onClick={onClick}
        title={toolTipText}
        $active={active}
        $badge={badge}
      >
        {children ? children : icon}
      </StyledNavAvatar>
    );
  }

  return (
    <StyledNavButton
      onClick={onClick}
      title={toolTipText}
      $active={active}
      $badge={badge}
    >
      {children ? children : icon}
    </StyledNavButton>
  );
};
