import styled from "styled-components";

const StatusBase = styled.div`
  padding: 7px 20px;
  border-radius: 30px;
  font-size: smaller;
  font-weight: 500;
  text-align: center;
  text-transform: capitalize;
`;

// @ts-ignore
export const StatusPublished = styled(StatusBase)`
  color: var(--rv-green-100);
  background-color: var(--rv-green-50);
  border: 1px solid  var(--v2-careplanbtton-border);

`;

export const StatusDraft = styled(StatusBase)`
  background-color: var(--color-primary-50);
  border: 1px solid  var(--v2-draft-border);

`;

// @ts-ignore
export const StatusChanged = styled(StatusBase)`
  color: var(--color-yellow-650);
  background-color: var(--color-yellow-30);
  border: 1px solid var(--color-yellow-border);
`;