import { useHistory } from "react-router-dom";

import { Button } from "../button/Button";
import { PatientInfo, UserInfo } from "../../lib/interfaces/user";
import { CaretLeft, Check, IconProps, Phone, NotePencil } from "phosphor-react";
import { AvatarLabelGroup } from "../avatarLabelGroup/AvatarLabelGroup";
import { Tag } from "../tag/Tag";
import { NavigationTabs } from "../navigationTabs/NavigationTabs";
import { Avatar } from "../avatar/Avatar";
import { patientRoutes } from "../../pages/patient/Patient";
import clsx from "clsx";
import styles from "./style.module.css";
import { useState } from "react";
import { useChats } from "../../lib/hooks/useChats";
import { ChatPreview as ChatPreviewType } from "../../lib/interfaces/messaging";
import { useBaseContext } from "../../containers/_base/Base";
import { JoinMeetingButton } from "../joinMeetingButton/JoinMeetingButton";
import { saveVideoStatus } from "../../lib/apis/availabilityManagement";
import { Visit } from "../../lib/interfaces/visits";

export interface PatientHeaderProps {
  patient: PatientInfo;
  showPatientsButton?: boolean;
  primaryAction?: (_?: any) => void;
  primaryActionLabel?: string;
  primaryActionIcon?: React.ForwardRefExoticComponent<
    IconProps & React.RefAttributes<SVGSVGElement>
  >;
  onAvatarClick?: () => void;
  onPatientsClick: () => void;
  careTeam?: UserInfo[];
  activeTab: string;
  titleOverride?: JSX.Element;
  onActiveTabChange: (route: string) => void;
  visit?: Visit;
}

export const PatientHeader = ({
  patient,
  showPatientsButton = true,
  primaryAction,
  primaryActionLabel,
  primaryActionIcon,
  onAvatarClick,
  onPatientsClick,
  careTeam,
  activeTab,
  titleOverride,
  onActiveTabChange,
  visit,
}: PatientHeaderProps) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const { getChatWithUsers } = useChats();
  const { openPopoutChat, userInfo } = useBaseContext();
  const { id: userId } = userInfo ?? {};

  const newMessageAction = () => {
    setLoading(true);
    getChatWithUsers(patient).then((res) => {
      const chat: ChatPreviewType | undefined = res?.chat;
      if (chat) {
        primaryAction && primaryAction(chat);
        openPopoutChat &&
          openPopoutChat({
            id: chat.id,
            participants: chat.participants,
            regarding: chat.regarding,
          });
        setLoading(false);
      }
    });
  };

  return showPatientsButton ? (
    <header className={styles.patientHeader}>
      <div className={styles.buttonsContainer}>
        <Button
          size="small"
          type="secondary-gray"
          label="Back"
          Icon={CaretLeft}
          iconPosition="left"
          onClick={onPatientsClick}
        />
        {visit && (
          <JoinMeetingButton
            visitDateTime={visit.dateTime}
            handleClick={() => {
              saveVideoStatus();
              history.push(`/video-chat/${visit.visitId}/${userId}`);
            }}
          />
        )}
      </div>
      <div className={styles.patientInfoContainer}>
        <AvatarLabelGroup
          data-dd-privacy="mask"
          users={[patient]}
          titleOverride={titleOverride}
        />
        {patient.checkedIn && (
          <Tag
            content="Checked In"
            type="success"
            Icon={Check}
            iconPosition="right"
          />
        )}
      </div>
      <div className={styles.bottomContainer}>
        <div className={styles.navContainer}>
          <NavigationTabs
            routes={patientRoutes}
            value={activeTab}
            onChange={onActiveTabChange}
          />
        </div>
        <div className={styles.careTeamContainer}>
          {careTeam && careTeam.length > 3 ? (
            <div onClick={onAvatarClick} style={{ cursor: "pointer" }}>
              <AvatarLabelGroup
                data-dd-privacy="mask"
                users={careTeam}
                size="sm"
                titleOverride={" "}
                avatarGroupGeneral={true}
                displayNames={false}
                className={styles.avatar}
              />
            </div>
          ) : (
            <div className={styles.careTeamContainer}>
              {careTeam?.map((provider) => (
                <Avatar
                  key={provider.id}
                  user={provider}
                  size="sm"
                  onClick={onAvatarClick}
                />
              ))}
            </div>
          )}
        </div>

        {primaryAction && (
          <Button
            label={primaryActionLabel}
            onClick={
              primaryActionLabel === "New Message"
                ? newMessageAction
                : primaryAction
            }
            Icon={primaryActionIcon}
            loading={loading}
            size="small"
          />
        )}
      </div>
    </header>
  ) : (
    <header className={clsx(styles.patientHeader)}>
      <div className={styles.patientInfoContainer}>
        <div className={styles.leftContainer}>
          <AvatarLabelGroup
            data-dd-privacy="mask"
            users={[patient]}
            titleOverride={titleOverride}
          />
        </div>
        <div className={styles.rightContainer}>
          {visit && (
            <JoinMeetingButton
              visitDateTime={visit.dateTime}
              handleClick={() => {
                saveVideoStatus();
                history.push(`/video/${visit.visitId}`);
              }}
            />
          )}
          {patient.checkedIn && (
            <Tag
              content="Checked In"
              type="success"
              Icon={Check}
              iconPosition="right"
            />
          )}
        </div>
      </div>
      <div className={styles.bottomContainer}>
        <div className={styles.navContainer}>
          <NavigationTabs
            routes={patientRoutes}
            value={activeTab}
            onChange={onActiveTabChange}
          />
        </div>
        <div className={styles.careTeamContainer}>
          {careTeam && careTeam.length > 3 ? (
            <div onClick={onAvatarClick}>
              <AvatarLabelGroup
                data-dd-privacy="mask"
                users={careTeam}
                size="sm"
                titleOverride={" "}
                avatarGroupGeneral={true}
                displayNames={false}
                className={styles.avatar}
              />
            </div>
          ) : (
            <div className={styles.careTeamContainer}>
              {careTeam?.map((provider) => (
                <Avatar
                  data-dd-privacy="mask"
                  key={provider.id}
                  user={provider}
                  size="sm"
                  onClick={onAvatarClick}
                />
              ))}
            </div>
          )}
        </div>
        {primaryAction && (
          <Button
            label={primaryActionLabel}
            onClick={
              primaryActionLabel === "New Message"
                ? newMessageAction
                : primaryAction
            }
            Icon={primaryActionIcon}
            loading={loading}
            size="small"
          />
        )}
      </div>
    </header>
  );
};
