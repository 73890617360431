import { MagnifyingGlass } from "phosphor-react";
import { useEffect, useMemo, useState, useRef } from "react";
import { Button, ButtonGroup } from "../../../../components/button/Button";
import {
  InteractiveList,
  Item,
} from "../../../../components/interactiveList/InteractiveList";
import { useMemoryRouter } from "../../../../components/modal/Modal";
import { Radio } from "../../../../components/radio/Radio";
import { Spinner } from "../../../../components/spinner/Spinner";
import { TextInput } from "../../../../components/textInput/TextInput";
import { Body } from "../../../../components/typography/body/Body";
import { usePatient } from "../../../../lib/hooks/usePatient";
import { UserInfo } from "../../../../lib/interfaces/user";
import styles from "./style.module.css";
import clsx from "clsx";
import { ParamProps, PatientNames } from "../../../../lib/apis/patient";
import useDebounce from "../../../../lib/hooks/useDebounce";
import { useCallback } from "react";
export interface PatientSelectFormSubmission {
  patient?: UserInfo;
  regarding?: UserInfo;
}

interface PatientSelectFormProps {
  patientAsParticipant?: boolean;
}

export const PatientSelectForm = ({
  patientAsParticipant = true,
}: PatientSelectFormProps) => {
  const debounce = useDebounce();
  const [allPatients, setAllPatients] = useState<UserInfo[]>([]);
  const [selectedPatient, setSelectedPatient] = useState<UserInfo>();
  const [providersOnly, setProvidersOnly] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const patientDropdownRef: any = useRef();
  const { pushRoute } = useMemoryRouter();
  const { getAllPatientNames } = usePatient();
  const abortControllerRef = useRef<AbortController | null>(null);
  const [params, setParams] = useState<ParamProps>({
    searchText: "",
    pageNumber: 1,
    pageSize: 50,
  });

  const getAllPatientSearchList = (signal: AbortSignal) => {
    setLoading(true);
    getAllPatientNames(params, signal)
      .then((res) => {
        if (res) {
          setAllPatients((prevPatients) => {
            const newPatients =
              res?.patients?.map((item: UserInfo) => ({
                id: item.userId,
                firstName: item.firstName,
                lastName: item.lastName,
                title: "patient",
                name: `${item.firstName} ${item.lastName}`,
              })) || [];
            if (params.pageNumber === 1) {
              return [...newPatients];
            } else {
              return [...prevPatients, ...newPatients];
            }
          });
          setTotalCount(res.totalCount);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (allPatients.length < totalCount || totalCount == 0) {
      getAllPatientSearchList(
        abortControllerRef.current?.signal ?? new AbortController().signal
      );
    }
  }, [params]);

  useEffect(() => () => setLoading(false), [allPatients]);
  useEffect(() => {
    if (providersOnly) setSelectedPatient(undefined);
  }, [providersOnly]);
  useEffect(() => {
    if (selectedPatient) setProvidersOnly(false);
  }, [selectedPatient]);

  const handleSelect = (patients: UserInfo) => {
    setSelectedPatient(patients);
  };

  const handleClickNext = () => {
    const props: PatientSelectFormSubmission = {};
    let subTitle: string | undefined = undefined;
    if (patientAsParticipant) {
      subTitle = "(Optional)";
      props.patient = selectedPatient;
      props.regarding = undefined;
    } else {
      props.patient = undefined;
      props.regarding = selectedPatient;
    }

    pushRoute("provider-select", { props, subTitle });
  };

  const handleDebounceFn = useCallback((value: string) => {
    setParams((prev) => ({ ...prev, pageNumber: 1, searchText: value }));
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();
  }, []);
  const debounceFn = useMemo(
    () => debounce(handleDebounceFn, 1000),
    [handleDebounceFn]
  );
  const handleSearchInput = (value: string) => {
    setSearchText(value);
    setTotalCount(0);
    debounceFn(value);
    if (patientDropdownRef.current) {
      patientDropdownRef.current.scrollTop = 0;
    }
  };
  // Handle scroll event to load more patients
  const handleDownScroll = useCallback(() => {
    if (allPatients.length > 0)
      setParams((prev) => ({ ...prev, pageNumber: params.pageNumber + 1 }));
  }, [allPatients.length]);

  // Attach scroll event listener to patientDropdownRef
  const handleScroll = useCallback(() => {
    if (patientDropdownRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        patientDropdownRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        handleDownScroll();
      }
    }
  }, [handleDownScroll]);

  // Effect to add and clean up scroll event listener
  useEffect(() => {
    const debouncedHandleScroll = debounce(handleScroll, 200); // Adjust the delay as needed
    if (patientDropdownRef.current) {
      patientDropdownRef.current.addEventListener(
        "scroll",
        debouncedHandleScroll
      );
      return () => {
        patientDropdownRef.current?.removeEventListener(
          "scroll",
          debouncedHandleScroll
        );
      };
    }
  }, [handleScroll]);

  return (
    <div className={styles.patientSelectForm}>
      <div className={styles.searchAndListContainer}>
        <TextInput
          value={searchText}
          onChange={handleSearchInput}
          LeftIcon={MagnifyingGlass}
        />
        <Body className={styles.listLabel} size="sm" color="secondary">
          Patients
        </Body>
        <div
          className={clsx(styles.listContaner, styles.scrollbar)}
          ref={patientDropdownRef}
        >
          {!patientAsParticipant && allPatients.length > 0 && (
            <div
              className={styles.providersOnlyOption}
              onClick={(e) => {
                e.stopPropagation();
                setProvidersOnly(true);
              }}
            >
              <Body size="md">No, message providers only.</Body>
              <Radio
                selected={providersOnly}
                onSelect={() => setProvidersOnly(true)}
              />
            </div>
          )}
          {allPatients && allPatients.length > 0 ? (
            <InteractiveList
              items={allPatients}
              value={selectedPatient} //@ts-ignore
              onChange={handleSelect}
            />
          ) : loading ? null : (
            <div className={styles.noResult}>No results found</div>
          )}
        </div>
        <div className={styles.spinnerWrapper}>
          {loading && <Spinner top={0} />}
        </div>
      </div>
      <ButtonGroup className={styles.buttonContainer}>
        <Button
          label="Next"
          disabled={!selectedPatient && !providersOnly}
          onClick={handleClickNext}
        />
      </ButtonGroup>
    </div>
  );
};
