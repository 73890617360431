import styled from "styled-components";

export const ContentWrapper = styled.div`
`;

export const Paragraph =  styled.div`
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%; 
color: #292524;
`;

export const ButtonWrapper = styled.div`
  margin-top: 25px;
  display: flex;
`;
