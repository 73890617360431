import { PublishModalTypes } from "./PillarCarePlanFooter";
import { Button, ButtonGroup } from "../../../components/button/Button";
import styles from "./style.module.css";
import { Body } from "../../../components/typography/body/Body";
import { Select } from "../../../components/select/Select";
import { Option } from "../../../lib/interfaces/input";
import { useContext, useState } from "react";
import {
  PillarDetailProps,
  PublishModalProps,
} from "../../../lib/interfaces/carePlan";
import { Box, Row, CarePlanIcon, CarePlanDetail, CarePlanItem } from "./Styled";
import { AlertContext, BaseContext } from "../../../lib/context/context";
import { useCarePlan } from "../../../lib/hooks/useCarePlan";
interface PublishUnpublishCarePlanProps {
  onClose: () => void;
  modalType: PublishModalTypes | undefined;
  carePlanDetail: PublishModalProps;
}

const initialSelectValue = { name: "Select one", value: null };

const publishOptions: Option[] = [
  { name: "Publish and Notify Patient", value: 1 },
  { name: "Publish and Do Not Notifiy the Patient", value: 0 },
];
const unpublishOptions: Option[] = [
  { name: "Unpublish and Notify Patient", value: 1 },
  { name: "Unpublish and Do Not Notifiy the Patient", value: 0 },
];

export default function PublishUnpublishCarePlan({
  onClose,
  modalType,
  carePlanDetail,
}: PublishUnpublishCarePlanProps) {
  const { pillarData, setPillarData } = useContext(BaseContext);
  const [currentSelectValue, SetCurrentSelectValue] =
    useState<Option>(initialSelectValue);
  const [loading, setLoading] = useState(false);
  const { pushAlert } = useContext(AlertContext);
  const isPublish = modalType === PublishModalTypes.Published;
  const isDraft = carePlanDetail.status !== PublishModalTypes.Draft;
  const selectOptions = isPublish ? publishOptions : unpublishOptions;
  const { updateCarePlanStatus } = useCarePlan();

  const handleSelect = (selectedOption: Option) => {
    SetCurrentSelectValue(selectedOption);
  };
  const handleSubmit = async (status: string) => {
    setLoading(true);
    const { careplanId, patient } = carePlanDetail;
    const actionType = status;
    const isNotify =
      carePlanDetail.status === PublishModalTypes.Draft
        ? 1
        : currentSelectValue.value;

    try {
      const res = await updateCarePlanStatus(
        careplanId,
        patient.id,
        actionType,
        isNotify
      );
      if (res) {
        const updatePillarData: PillarDetailProps = {
          id: pillarData?.id || "",
          pillarName: pillarData?.pillarName || "",
          icons: pillarData?.icons,
          contentData: res,
        };
        setPillarData(updatePillarData);
      }
    } catch (error) {
      pushAlert("Failed to update Care Plan status.", "danger");
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <div>
      <Body>
        {isPublish
          ? `Care Plan will publish to the Patient App and notify the Patient their Care Plan is ready to view.`
          : `Unpublishing a Care Plan will remove it from the Patient App experience.`}
      </Body>

      <Box>
        <Row>
          <CarePlanIcon>
            <img
              src={pillarData?.icons?.smallColorIcon}
              alt="icon"
              width={24}
              height={24}
            />
          </CarePlanIcon>
          <CarePlanItem isPatientInfo>
            <span>Patient</span>
            <strong>{carePlanDetail.patient.name}</strong>
          </CarePlanItem>
          <CarePlanDetail>
            <CarePlanItem>
              <span>{carePlanDetail.title}</span>
              <strong>Guide</strong>
            </CarePlanItem>
            <CarePlanItem>
              <span>Patient Group</span>
              <strong>{carePlanDetail.needType}</strong>
            </CarePlanItem>
            <CarePlanItem>
              <span>Updated</span>
              <span>{carePlanDetail.updatedAt}</span>
            </CarePlanItem>
          </CarePlanDetail>
        </Row>
      </Box>

      {isDraft && (
        <>
          <Body className={styles.selectHeading} size="sm">
            {isPublish ? `Publishing Options` : `Unpublishing Options`}
          </Body>
          <Select
            value={currentSelectValue}
            onChange={handleSelect}
            options={selectOptions}
            placeholder="Select one"
          ></Select>
        </>
      )}

      <ButtonGroup align="end" className={styles.buttonGroup}>
        {isPublish ? (
          <>
            <Button
              onClick={() => {
                onClose();
              }}
              label="Cancel"
              type="secondary"
              size="small"
            />
            <Button
              loading={loading}
              onClick={() => {
                handleSubmit("publish");
              }}
              label={isDraft ? "Confirm" : "Publish to Patient App"}
              size="small"
              disabled={isDraft && currentSelectValue.value === null}
            />
          </>
        ) : (
          <>
            <Button
              loading={loading}
              onClick={() => {
                handleSubmit("unpublish");
              }}
              label="Unpublish from Patient App"
              type="secondary"
              size="small"
              disabled={currentSelectValue.value === null}
            />
            <Button
              onClick={() => {
                onClose();
              }}
              label="Keep Published"
              size="small"
            />
          </>
        )}
      </ButtonGroup>
    </div>
  );
}
