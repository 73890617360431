import styled from "styled-components";

export const ContentWrapper = styled.div`
   height:200px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
`;
