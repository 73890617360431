import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Switch, Route, useHistory } from "react-router-dom";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import config from "./config/okta.config.ts";
import ddConfig from "./config/datadog.config.ts";
import Login from "./pages/login/Login";
import { VideoCall } from "./pages/videoChat/VideoCall";
import { Base } from "./containers/_base/Base.tsx";
import { AppContextProvider } from "./lib/context/appContext";
import './config/tracing.ts';

const oktaAuth = new OktaAuth(config.auth);
const env = process.env.REACT_APP_DD_ENV;
const isTracingEnabled = !(env === "local" || env === "dev");

if (isTracingEnabled) {
  // initialize DataDog RUM && Session Replay
  datadogRum.init(ddConfig.rum);
  // initialize DataDog Logging
  datadogLogs.init(ddConfig.logs);
}

export default function App() {
  const history = useHistory();
  const queryClient = new QueryClient();

  const onAuthRequired = () => history.push("/login");
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={onAuthRequired}
      restoreOriginalUri={restoreOriginalUri}
    >
      <AppContextProvider>
        <QueryClientProvider client={queryClient}>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route path="/login/callback" component={LoginCallback} />
            <SecureRoute path="/test">here</SecureRoute>
            <SecureRoute
              path={`/video-chat/:visitId/:userId`}
              component={VideoCall}
            />
            <SecureRoute path="/" component={Base} />
          </Switch>
        </QueryClientProvider>
        <Switch></Switch>
      </AppContextProvider>
    </Security>
  );
}
