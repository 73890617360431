import React, { useContext, useEffect, useRef, useState } from "react";
import { Select } from "../../../components/select/Select";
import { Button, ButtonGroup } from "../../../components/button/Button";
import { TextInput } from "../../../components/textInput/TextInput";
import { Article } from "phosphor-react";
import _ from "lodash";
import { Paperclip, File as FileIcon, X } from "phosphor-react";
import styles from "./style.module.css";

import {
  ActionButtonGroup,
  ActionsWrapper,
  AttachmentContent,
  AttachmentDescription,
  AttachmentTitle,
  AttachmentWrapper,
  BrowseLink,
  Card,
  CardGroup,
  Container,
  ContentWrapper,
  Description,
  FileAttachmentContent,
  FileAttachmentWrapper,
  FileDetails,
  FileInfo,
  FileName,
  FileThumbnail,
  Filler,
  Footer,
  FooterText,
  Header,
  HeaderContent,
  HeaderDescription,
  ImageTitle,
  Label,
  MainContent,
  Navbar,
  NavbarContainer,
  NavbarItem,
  Progress,
  ProgressLabel,
  RemoveFileButton,
  Section,
  Tag,
  TextBlock,
  TextWrapper,
  Title,
  UploadProgressWrapper,
} from "./Styled";
import { Option } from "../../../lib/interfaces/input";
import { useParams } from "react-router-dom";
import { useCarePlan } from "../../../lib/hooks/useCarePlan";
import { PatientInfo } from "../../../lib/interfaces/user";

import { Modal } from "../../../components/modal/Modal";
import { AlertContext, BaseContext } from "../../../lib/context/context";
import {
  CarePlanContent,
  ContentData,
  PillarDetailProps,
} from "../../../lib/interfaces/carePlan";
import { ExitCarePlan } from "../../../components/carePlan/ExitCarePlan";
import { CarePlanStatus } from "../../../components/carePlanStatus/CarePlanStatus";
import { Spinner } from "../../../components/spinner/Spinner";
import { Editor } from "../../../components/editor/Editor";
import { marked } from "marked";
import { careplanPatientDetailFormatDateTime } from "../../../lib/util/date";

export interface AddedFile {
  name: string;
  file: globalThis.File;
}
interface PatientRouteParam {
  userId: string;
}

export interface CarePlanProps {
  patient: PatientInfo;
  pillarDatas: PillarDetailProps | null;
  onClose: () => void;
}

export const PillarEdit = ({
  patient,
  pillarDatas,
  onClose,
}: CarePlanProps) => {
  const { userId } = useParams<PatientRouteParam>();
  const { pushAlert } = useContext(AlertContext);
  const {
    updateCarePlan,
    removeCarePlan,
    addCarePlanAttachment,
    fetchArchiveDetailById,
    dateFormatWithTime,
    formatFileSize,
    formatLastModifiedDate,
  } = useCarePlan();

  const controller = new AbortController();
  const { setPillarData, setIsPillarPageShow, setIsPillarEditShow } =
    useContext(BaseContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fileUploading, setFileUploading] = useState<boolean>(false);
  const [showAttachmentModal, setShowAttachmentModal] =
    useState<boolean>(false);
  const [showExitModal, setShowExitModal] = useState<boolean>(false);
  const [duplicateFileUpload, setDuplicateFileUpload] =
    useState<boolean>(false);
  const [addedFiles, setAddedFiles] = useState<AddedFile[]>([]);
  const [pillarDetails, setPillarDetails] = useState<PillarDetailProps | null>(
    null
  );
  const [selectedCarePlanTemplate, setSelectedCarePlanTemplate] = useState({
    value: "",
    name: "",
  });
  const [carePlanTemplateOptions, setCarePlanTemplateOptions] = useState<
    Option[]
  >([]);

  const [fileUploadPercentage, setFileUploadPercentage] = useState<number>(0);

  useEffect(() => {
    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (pillarDatas) {
      const carePlanData = pillarDatas.contentData.content;
      const summaryContent = carePlanData?.carePlanContent?.find(
        (content: CarePlanContent) => content?.tabName?.title === "Summary"
      );

      if (summaryContent) {
        const updatePillarData = async () => {
          // Resolve the content body using marked
          const convertMarkToHTML = summaryContent.body
            ? await marked(summaryContent.body)
            : "";

          // Create a new object with the resolved body
          const summaryContentWithResolvedBody: CarePlanContent = {
            ...summaryContent,
            body: convertMarkToHTML, // Replace the original body with the resolved one
          };

          setPillarDetails({
            ...pillarDatas,
            contentData: {
              ...pillarDatas.contentData,
              content: {
                ...carePlanData,
                carePlanContent: [summaryContentWithResolvedBody], // Use the updated content
              },
            },
          });

          const contentTemplateData: Option[] =
            (pillarDatas?.contentData?.archiveContentData &&
              pillarDatas?.contentData?.archiveContentData?.length > 0 &&
              pillarDatas?.contentData?.archiveContentData?.map((item) => ({
                value: item.archiveId,
                name: item.contentName,
              }))) ||
            [];
          if (contentTemplateData?.length > 0) {
            setCarePlanTemplateOptions(contentTemplateData);
          }
        };
        updatePillarData();
      }
    }
  }, [pillarDatas]);

  const handleContentChange = (field: keyof CarePlanContent, value: string) => {
    setPillarDetails((prevState: PillarDetailProps | null) => {
      if (!prevState) return null;

      const updatedCarePlanContent = {
        ...prevState.contentData.content.carePlanContent[0],
        [field]: value,
      };

      return {
        ...prevState,
        contentData: {
          ...prevState.contentData,
          content: {
            ...prevState.contentData.content,
            carePlanContent: [updatedCarePlanContent],
          },
        },
      };
    });
  };

  const handleCareTemplateSelection = async (value: Option) => {
    setSelectedCarePlanTemplate(value);
    const archiveId = value.value;
    setIsLoading(true);
    try {
      const res = await fetchArchiveDetailById(archiveId);
      if (res) {
        const contentData: ContentData = res;
        setPillarDetails((prevState: PillarDetailProps | null) => {
          if (prevState) {
            return { ...prevState, contentData: contentData };
          } else {
            return {
              contentData: contentData,
            } as PillarDetailProps;
          }
        });
        setIsLoading(false);
      } else {
        pushAlert(
          "Failed to get archive's details care plan pillars.",
          "danger"
        );
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      console.error("Error fetching archive details:", error.message || error);
      pushAlert("An error occurred while fetching archive details.", "danger");
    }
  };

  const updateCarePlanHandler = async () => {
    // Your submit logic here
    if (
      pillarDetails?.contentData?.pillarId &&
      pillarDetails?.contentData.careplanId
    ) {
      const pillarId = pillarDetails?.contentData?.pillarId;
      const patientGroupTypeId = pillarDetails?.contentData?.patientGroupTypeId;
      const careplanId = pillarDetails?.contentData.careplanId;
      const currentData = pillarDetails?.contentData.content;
      try {
        const res = await updateCarePlan(
          userId,
          pillarId,
          patientGroupTypeId,
          careplanId,
          currentData
        );

        if (res) {
          const updatePillarData: PillarDetailProps = {
            id: pillarId,
            pillarName: pillarDetails?.pillarName || "",
            icons: pillarDetails?.icons,
            contentData: res,
          };
          setPillarData(updatePillarData);
          onClose();
        }
      } catch (err) {
        pushAlert("Failed to update Care Plan. Please try again.", "danger");
        console.error(err);
      }
    }
  };

  const removeCarePlanHandler = async () => {
    try {
      const careplanId = pillarDetails?.contentData.careplanId;
      if (userId && careplanId) {
        const response = await removeCarePlan(userId, careplanId);

        if (response) {
          setIsPillarPageShow(false);
          setIsPillarEditShow(false);
        }
      }
    } catch (err) {
      pushAlert("Failed to remove Care Plan. Please try again.", "danger");
      console.error(err);
    }
  };

  const [scrolled, setScrolled] = useState(false);
  const contentWrapperRef = useRef<HTMLDivElement>(null);
  const filePickerRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      const offset = contentWrapperRef.current?.scrollTop ?? 0;
      if (offset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    const contentWrapper = contentWrapperRef.current;
    if (contentWrapper) {
      contentWrapper.addEventListener("scroll", handleScroll);
    }

    return () => contentWrapper?.removeEventListener("scroll", handleScroll);
  }, []);

  const handleAddAttachmentClick = () => {
    filePickerRef.current?.click();
  };

  const handleFilePick = async (_files: FileList | null) => {
    const updFiles = Object.values(_files || {});
    const maxFileSizeInBytes = 5 * 1024 * 1024; // 5 MB
    if (updFiles.length > 0) {
      if (duplicateFileUpload) setDuplicateFileUpload(false);
      let updAddedFiles: AddedFile[] = _.cloneDeep(addedFiles);
      // Filter files that are larger than 5 MB
      const validFiles = updFiles.filter(
        (file) => file.size <= maxFileSizeInBytes
      );
      if (validFiles.length !== updFiles.length) {
        pushAlert("The file size should not exceed 5 MB", "danger");
        setShowAttachmentModal(false);
      }
      updAddedFiles = [
        ...updAddedFiles,
        ...validFiles.map((file) => ({ name: file.name, file })),
      ];
      setAddedFiles(updAddedFiles);
    } else {
      setDuplicateFileUpload(true);
    }
  };

  const onProgress = (progress: number) => {
    setFileUploadPercentage(progress);
  };

  const handleFileUpload = async () => {
    setFileUploading(true);
    const fileUploadResponses = addedFiles.map((_file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async () => {
          if (reader.result) {
            const name = _file.name;
            const file = reader.result.toString();
            try {
              const res = await addCarePlanAttachment(
                userId,
                name,
                file,
                onProgress
              );
              if (res.attachmentUrl) {
                handleContentChange("attachment", res.attachmentUrl);
                resolve(true);
              } else {
                resolve(false);
              }
            } catch (err: any) {
              const errorMsg = `Failed to upload file: ${_file.name}. Error: ${err?.message}`;
              console.error(errorMsg);
              reject(err);
            }
          } else resolve(false);
        };
        reader.onerror = (error: any) => {
          const errorMsg = `FileReader error for file: ${_file.name}. Error: ${error.message}`;
          console.error(errorMsg);
          reject(error);
        };
        reader.readAsDataURL(_file.file);
      });
    });
    const fileUploadRes = await Promise.all(fileUploadResponses);
    setFileUploading(false);
    if (!fileUploadRes.includes(false))
      pushAlert("Files added successfully", "success");
    else pushAlert("Files unable to be uploaded. Please try again.", "danger");
    //refresh the files table
    setShowAttachmentModal(false);
    setFileUploadPercentage(0);
    setAddedFiles([]);
  };

  return (
    <>
      {showExitModal && (
        <Modal
          visible={showExitModal}
          onCloseModal={() => {}}
          title="Save changes before exiting?"
          dismissable={false}
          className={styles.modalExit}
        >
          <ExitCarePlan
            onSubmit={() => {
              updateCarePlanHandler();
            }}
            exitWithOutSave={() => {
              setShowExitModal(false);
              onClose();
            }}
            onCancel={() => setShowExitModal(false)}
            isLoading={false}
            disabled={pillarDetails?.contentData.archiveId ? true : false}
          />
        </Modal>
      )}

      {showAttachmentModal && (
        <Modal
          visible={showAttachmentModal}
          onCloseModal={() => {}}
          title="Upload and attach file"
          subTitle=" Upload and attach file to this care plan."
          dismissable={false}
        >
          <AttachmentWrapper>
            <div
              onDragOver={(e) => {
                e.preventDefault();
              }}
              onDrop={(e) => {
                e.preventDefault();
                const files = e.dataTransfer.files;
                if (!addedFiles.length) {
                  handleFilePick(files);
                }
              }}
            >
              <AttachmentContent>
                <input
                  ref={filePickerRef}
                  type="file"
                  accept="image/*,.pdf"
                  style={{ display: "none" }}
                  onChange={(e) => handleFilePick(e.target.files)}
                  multiple={false}
                />
                <Button
                  type="secondary-gray"
                  onClick={handleAddAttachmentClick}
                  Icon={Paperclip}
                  disabled={addedFiles?.length ? true : false}
                />
                <AttachmentTitle>Drop your image here or</AttachmentTitle>
                <BrowseLink>browse</BrowseLink>
                <AttachmentDescription>
                  Supported formats: PNG, JPG, JPEG, WEBP | 5MB Max
                </AttachmentDescription>
              </AttachmentContent>
            </div>
          </AttachmentWrapper>

          <>
            {addedFiles.map((_file, index) => (
              <React.Fragment key={index}>
                <FileAttachmentWrapper>
                  <RemoveFileButton
                    onClick={() => {
                      setAddedFiles((prevFiles) =>
                        prevFiles.filter((_, i) => i !== index)
                      );
                      setFileUploadPercentage(0);
                    }}
                  >
                    <X />
                  </RemoveFileButton>
                  <FileAttachmentContent>
                    <FileThumbnail>
                      <Paperclip size="25px" />
                    </FileThumbnail>
                    <FileInfo>
                      <FileName>{_file.name}</FileName>
                      <FileDetails>{`${formatFileSize(
                        _file.file.size
                      )} | ${formatLastModifiedDate(
                        _file?.file?.lastModified
                      )}`}</FileDetails>
                    </FileInfo>
                  </FileAttachmentContent>
                  <UploadProgressWrapper>
                    <Progress>
                      <Filler progress={fileUploadPercentage}></Filler>
                    </Progress>
                    <ProgressLabel>{`${fileUploadPercentage}%`}</ProgressLabel>
                  </UploadProgressWrapper>
                </FileAttachmentWrapper>
              </React.Fragment>
            ))}
          </>
          <ActionsWrapper>
            <ActionButtonGroup>
              <Button
                onClick={() => setShowAttachmentModal(false)}
                type="secondary"
                size="small"
                label="Cancel"
              />
              <Button
                onClick={handleFileUpload}
                loading={fileUploading}
                label="Attach and save"
                size="small"
                disabled={!addedFiles.length}
              />
            </ActionButtonGroup>
          </ActionsWrapper>
        </Modal>
      )}

      {isLoading ? (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      ) : (
        <>
          <Container>
            <Header>
              <HeaderContent>
                <HeaderDescription scrolled={scrolled}>
                  {pillarDetails?.contentData.status && (
                    <Tag>
                      <CarePlanStatus
                        status={pillarDetails?.contentData.status}
                      />
                    </Tag>
                  )}

                  <HeaderDescription scrolled={scrolled}>
                    <Title scrolled={scrolled}>
                      {pillarDetails?.contentData?.content?.pillar.pillarName}
                    </Title>
                    {patient?.name && (
                      <Description>
                        {`${patient.name}`} • {patient.assignedSex} •{" "}
                        {patient?.dateOfBirth &&
                          careplanPatientDetailFormatDateTime(
                            patient?.dateOfBirth
                          )}{" "}
                        {patient?.identifiers?.epic && (
                          <> • {patient?.identifiers?.epic}</>
                        )}
                      </Description>
                    )}
                  </HeaderDescription>
                </HeaderDescription>

                <Select
                  value={selectedCarePlanTemplate}
                  options={carePlanTemplateOptions}
                  placeholder="Care Plan Template"
                  onChange={handleCareTemplateSelection}
                  width="150"
                  direction="right"
                  border={true}
                  className={styles.selectFormatting}
                />
              </HeaderContent>
            </Header>
          </Container>

          <ContentWrapper ref={contentWrapperRef}>
            <MainContent>
              <CardGroup>
                <Card>
                  {pillarDetails?.contentData?.content.carePlanContent[0]
                    ?.mediaUrl && (
                    <table width="100%">
                      <tr>
                        <td width="220px">
                          <img
                            src={
                              pillarDetails?.contentData?.content
                                .carePlanContent[0]?.mediaUrl
                            }
                            alt="Pillar Illustration"
                          />
                        </td>
                        <td valign="middle">
                          <ImageTitle>
                            {pillarDetails?.contentData?.content.carePlanContent[0]?.mediaUrl
                              ?.split("/")
                              .pop()}
                          </ImageTitle>
                        </td>
                      </tr>
                    </table>
                  )}
                </Card>
                <Section>
                  <TextBlock>
                    <Label>Title</Label>
                    <TextWrapper>
                      <TextInput
                        placeholder="Enter a title"
                        value={
                          pillarDetails?.contentData.content.carePlanContent[0]
                            ?.title || ""
                        }
                        onChange={(value) =>
                          handleContentChange("title", value)
                        }
                      />
                      <Button
                        label="Attach file"
                        type="primary"
                        onClick={() => {
                          setShowAttachmentModal(true);
                        }}
                        Icon={Article}
                        iconPosition="right"
                      />
                    </TextWrapper>
                    <Label>Body Text</Label>

                    <Editor
                      key={0}
                      value={
                        pillarDetails?.contentData?.content?.carePlanContent[0]
                          ?.body || ""
                      }
                      onChange={(value) => {
                        handleContentChange("body", value);
                      }}
                    />
                  </TextBlock>
                </Section>
              </CardGroup>
            </MainContent>
            <br />

            <Footer>
              <Button
                size="small"
                type="secondary"
                label="Exit"
                iconPosition="left"
                onClick={() => setShowExitModal(true)}
              />
              <ButtonGroup>
                <>
                  {pillarDetails?.contentData &&
                    pillarDetails?.contentData?.publishedAt && (
                      <FooterText>
                        Saved{" "}
                        {dateFormatWithTime(
                          pillarDetails?.contentData?.publishedAt
                        )}
                      </FooterText>
                    )}
                </>

                <Button
                  size="small"
                  type="secondary"
                  label="Delete"
                  iconPosition="left"
                  onClick={removeCarePlanHandler}
                  disabled={pillarDetails?.contentData.archiveId ? true : false}
                />
                <Button
                  size="small"
                  type="primary"
                  label="Save"
                  iconPosition="left"
                  onClick={updateCarePlanHandler}
                  disabled={pillarDetails?.contentData.archiveId ? true : false}
                />
              </ButtonGroup>
            </Footer>
          </ContentWrapper>
        </>
      )}
    </>
  );
};
