import { useContext, useEffect, useState } from "react";
import { Modal } from "../../components/modal/Modal";
import { Option } from "../../lib/interfaces/input";
import { AddCareForm } from "../../components/carePlan/AddCarePlanForm/index";
import { Pillar } from "../../components/carePlan/Pillar";
import {
  IconData,
  PatientGroupType,
  PillarDetailProps,
} from "../../lib/interfaces/carePlan";

import styles from "./style.module.css";
import nutritionGray from "../../assets/icons/nutritionGray.svg";
import nutritionColor from "../../assets/icons/nutritionColor.svg";
import nutritionColorSmall from "../../assets/icons/nutritionColorSmall.svg";
import mindfullnessGray from "../../assets/icons/mindfullnessGray.svg";
import mindfullnessColor from "../../assets/icons/mindfullnessColor.svg";
import mindfullnessColorSmall from "../../assets/icons/mindfullnessColorSmall.svg";
import movementGray from "../../assets/icons/movementGray.svg";
import movementColor from "../../assets/icons/movementColor.svg";
import movementColorSmall from "../../assets/icons/movementColorSmall.svg";
import sleepGray from "../../assets/icons/sleepGray.svg";
import sleepColor from "../../assets/icons/sleepColor.svg";
import sleepColorSmall from "../../assets/icons/sleepColorSmall.svg";
import { CarePlanProps } from "./CarePlan";
import { useCarePlan } from "../../lib/hooks/useCarePlan";
import { AlertContext, BaseContext } from "../../lib/context/context";
import { Spinner } from "../../components/spinner/Spinner";
import { useParams } from "react-router-dom";
import { logError } from "../../lib/util/logger";

const pillarsDataIcons = [
  {
    pillarName: "Nutrition",
    icons: {
      grayIcon: nutritionGray,
      colorIcon: nutritionColor,
      smallColorIcon: nutritionColorSmall,
    },
  },
  {
    pillarName: "Emotional Health",
    icons: {
      grayIcon: mindfullnessGray,
      colorIcon: mindfullnessColor,
      smallColorIcon: mindfullnessColorSmall,
    },
  },
  {
    pillarName: "Movement & Exercise",
    icons: {
      grayIcon: movementGray,
      colorIcon: movementColor,
      smallColorIcon: movementColorSmall,
    },
  },
  {
    pillarName: "Sleep Hygiene",
    icons: {
      grayIcon: sleepGray,
      colorIcon: sleepColor,
      smallColorIcon: sleepColorSmall,
    },
  },
];
interface ShowModalProps {
  isVisible: boolean;
  title: string;
  pillarId: string;
  colorIcon: string;
  icons: IconData;
}

const initialShowCarePlain: ShowModalProps = {
  isVisible: false,
  title: "",
  pillarId: "",
  colorIcon: "",
  icons: {
    grayIcon: "",
    colorIcon: "",
    smallColorIcon: "",
  },
};

const initialPillarValue: Option = {
  name: "Select one",
  value: "",
};

interface PatientRouteParam {
  userId: string;
}

export const CarePlanPillar = ({ patient }: CarePlanProps) => {
  const { userId } = useParams<PatientRouteParam>();
  const {
    getPatientPillars,
    getPillarsPatientGroups,
    getCarePlanListById,
    addCarePlan,
  } = useCarePlan();
  const { setIsPillarPageShow, setPillarData } = useContext(BaseContext);
  const { pushAlert } = useContext(AlertContext);

  const controller = new AbortController();

  useEffect(() => {
    return () => controller.abort();
  }, []);

  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [carePlanPillarLists, setCarePlanPillarLists] =
    useState<PillarDetailProps[]>();
  const [showAddCarePlanModal, setShowAddCarePlanModal] =
    useState<ShowModalProps>(initialShowCarePlain);
  const [selectedPillarValue, setSelectedPillarValue] =
    useState<Option>(initialPillarValue);
  const [groupTypeOptions, setGroupTypeOptions] = useState<Option[]>();

  useEffect(() => {
    fetPatientPillars();
    fetchPillarsPatientGroups();
  }, [patient]);

  const fetPatientPillars = async () => {
    try {
      setLoading(true);
      const result = await getPatientPillars(patient.id, controller);

      if (result) {
        const carePlanPillarsData = result.map((item: PillarDetailProps) => {
          const pillarIcons = pillarsDataIcons.find((p) =>
            p.pillarName.toLowerCase().includes(item.pillarName.toLowerCase())
          );

          if (item && item.contentData) {
            return {
              ...item,
              icons: pillarIcons ? pillarIcons.icons : null,
              contentData: {
                ...item.contentData,
                createdBy: item?.contentData?.updatedBy
                  ? item?.contentData?.updatedBy
                  : "",
              },
            };
          } else {
            return {
              ...item,
              icons: pillarIcons ? pillarIcons.icons : null,
            };
          }
        });

        setCarePlanPillarLists(carePlanPillarsData);
      }
    } catch (error) {
      handleError("Error fetching patient pillars.", error, {userId: patient.id});
    } finally {
      setLoading(false);
    }
  };

  const fetchPillarsPatientGroups = () => {
    getPillarsPatientGroups(controller).then((result) => {
      if (result) {
        const groupTypeOptionsData = result.map((item: PatientGroupType) => ({
          name: item.name,
          value: item.id,
        }));
        setGroupTypeOptions(groupTypeOptionsData);
      }
    });
  };

  const handleAddCarePlans = (item: PillarDetailProps) => {
    setShowAddCarePlanModal({
      isVisible: true,
      title: item.pillarName || "",
      pillarId: item.id,
      colorIcon: item?.icons?.smallColorIcon || "",
      icons: item?.icons || {},
    });
  };

  const handleSelection = (value: Option) => {
    setSelectedPillarValue(value);
  };

  const handleSubmit = async () => {
    const pillarId = showAddCarePlanModal.pillarId;
    const patientGroupTypeId = selectedPillarValue.value;

    if (pillarId && selectedPillarValue?.value) {
      setSubmitButtonLoading(true);
      try {
        const result = await fetchCarePlanList(pillarId, patientGroupTypeId);

        if (!result?.length) {
          handleNoCarePlanResult();
        } else {
          await handleAddCarePlan(result);
        }
      } catch (error) {
        handleError("Failed to retrieve CarePlan list. Please try again.", error, {pillarId: pillarId});
      } finally {
        setSubmitButtonLoading(false);
      }
    }
  };

  const fetchCarePlanList = async (
    pillarId: string,
    patientGroupTypeId: string
  ) => {
    try {
      return await getCarePlanListById(
        pillarId,
        patientGroupTypeId,
        controller
      );
    } catch (error) {
      handleError("Error occurred while retrieving CarePlan list.", error, {pillarId: pillarId}); 
    }
  };

  const handleNoCarePlanResult = () => {
    pushAlert("Content is not received from Contentful.", "danger");
    resetFormState();
  };

  const handleAddCarePlan = async (result: any[]) => {
    const [currentData] = result;

    if (!currentData) {
      handleError("No data provided for Add CarePlan.", null, {userId: userId});
      return;
    }

    try {
      const res = await addCarePlan(
        userId,
        showAddCarePlanModal.pillarId,
        selectedPillarValue.value,
        currentData
      );

      if (res) {
        updatePillarData(res);
        resetFormState();
      }
    } catch (error) {
      handleError("Failed to add CarePlan. Please try again.", error, {userId: userId});
    }
  };

  const resetFormState = () => {
    setShowAddCarePlanModal(initialShowCarePlain);
    setSelectedPillarValue(initialPillarValue);
  };

  const updatePillarData = (res: any) => {
    const updatePillarData: PillarDetailProps = {
      id: showAddCarePlanModal?.pillarId,
      pillarName: showAddCarePlanModal?.title || "",
      icons: showAddCarePlanModal?.icons,
      contentData: res,
    };
    setPillarData(updatePillarData);
    setIsPillarPageShow(true);
  };

  const handleError = (message: string, error: any, context: object) => {
    logError(message, context, error);
    pushAlert(message, "danger");
  };

  return loading ? (
    <Spinner />
  ) : (
    <>
      {showAddCarePlanModal.isVisible && (
        <Modal
          visible={showAddCarePlanModal.isVisible}
          onCloseModal={() => {}}
          title="Care Plan Template"
          className={styles.modalFormat}
          dismissable={false}
          icon={{
            title: showAddCarePlanModal.title,
            iconColor: showAddCarePlanModal.colorIcon,
          }}
          verticalPosition="center"
        >
          <AddCareForm
            value={selectedPillarValue}
            options={groupTypeOptions || []}
            onChange={handleSelection}
            onSubmit={handleSubmit}
            onCancel={() => {
              setShowAddCarePlanModal(initialShowCarePlain);
              setSelectedPillarValue(initialPillarValue);
            }}
            isLoading={submitButtonLoading}
          />
        </Modal>
      )}
      {carePlanPillarLists?.length &&
        carePlanPillarLists?.map((item: PillarDetailProps) => (
          <Pillar
            pillarDetail={item}
            isPillarInfoAvailable={!!item?.contentData}
            onClick={() => {
              handleAddCarePlans(item);
            }}
            {...(typeof setIsPillarPageShow === "function"
              ? { setIsPillarPageShow }
              : undefined)}
          />
        ))}
      <br />
    </>
  );
};
