import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { Resource } from '@opentelemetry/resources';
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';
import { registerInstrumentations } from '@opentelemetry/instrumentation';

const getDeviceType = () => {
  const userAgent = navigator.userAgent.toLowerCase();

  if (userAgent.includes("android") || userAgent.includes("iphone")) {
    return "Mobile";
  } else if (userAgent.includes("ipad") || userAgent.includes("tablet")) {
    return "Tablet";
  } else {
    return "Desktop";
  }
};

const HOST = "https://browser-intake-datadoghq.com";
const PATH = `/api/v2/rum?dd-api-key=${process.env.REACT_APP_DD_CLIENT_TOKEN}&ddsource=browser&service=${process.env.REACT_APP_DD_SERVICE}`;
const url = `${HOST}${PATH}`;

const provider = new WebTracerProvider({
  resource: new Resource({
    'service.name': process.env.REACT_APP_DD_SERVICE,
    'device.type': getDeviceType(),
  }),
});

const exporter = new OTLPTraceExporter({
  url: url,
});

provider.addSpanProcessor(new BatchSpanProcessor(exporter));

provider.register();

registerInstrumentations({
  instrumentations: [getWebAutoInstrumentations()],
});
