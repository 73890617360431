import { Button } from "../../button/Button";
import { CarePlanStatus } from "../../carePlanStatus/CarePlanStatus";
import { PublishModalProps } from "../../../lib/interfaces/carePlan";
import {
  PillarInfoContainer,
  PillarTypeLabel,
  PillarInfoUpdate,
} from "./Styled";
import { useContext } from "react";
import { BaseContext } from "../../../lib/context/context";

export const CarePlanPillarDetail = ({
  pillarDetail,
}: {
  pillarDetail: Omit<PublishModalProps, "patient" | "careplanId">;
}) => {
  const { allProviders } = useContext(BaseContext);

  function getProviderNameById(providerId: string) {
    return (
      allProviders?.find((provider) => provider.providerId === providerId)
        ?.name ?? ""
    );
  }

  return (
    <PillarInfoContainer>
      <PillarTypeLabel>
        <span>{pillarDetail.title}</span>
        {pillarDetail?.needType && <span>{pillarDetail.needType}</span>}
      </PillarTypeLabel>

      <PillarInfoUpdate>
        <span>Updated {pillarDetail.updatedAt}</span>
        {pillarDetail.updatedBy && (
          <span>{getProviderNameById(pillarDetail.updatedBy)}</span>
        )}
      </PillarInfoUpdate>

      {pillarDetail.status !== undefined && (
        <CarePlanStatus status={pillarDetail.status} />
      )}

      <Button
        onClick={pillarDetail.onEditClick ?? (() => {})}
        label="Edit"
        type="secondary"
        size="small"
      />
    </PillarInfoContainer>
  );
};
