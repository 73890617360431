import React, { useState } from "react";
import { IconProps } from "phosphor-react";
import clsx from "clsx";
import styles from "./style.module.css";
import { CustomIcon } from "../customIcons/CustomIcons";

export type ButtonType =
  | "primary"
  | "secondary"
  | "secondary-gray"
  | "tertiary"
  | "transparent";
export type ButtonSize = "medium" | "small";
export type IconPostion = "left" | "right";
export type IconPosition = "left" | "right";

const Spinner = () => (
  <svg
    className={styles.spinner}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16C12.418 16 16 12.4185 16 8C16 3.58154 12.418 0 8 0C3.58203 0 0 3.58154 0 8C0 12.4185 3.58203 16 8 16ZM8 14C11.3125 14 14 11.3135 14 8C14 4.68652 11.3125 2 8 2C4.6875 2 2 4.68652 2 8C2 11.3135 4.6875 14 8 14Z"
      fill="#D6D3D1"
    />
    <path
      d="M4.9375 15.3911C5.60077 15.6651 6.29491 15.8486 7.0026 15.9376C7.55057 16.0064 8 15.5523 8 15C8 14.4477 7.54924 14.0085 7.00462 13.9168C6.55972 13.842 6.12366 13.7169 5.70312 13.5435C4.97656 13.2417 4.31641 12.7998 3.75781 12.2427C3.19922 11.6855 2.75781 11.0239 2.45703 10.2959C2.28385 9.87701 2.15859 9.44063 2.08347 8.99535C1.9916 8.45076 1.55228 8 1 8C0.447715 8 -0.00644753 8.44943 0.0624434 8.9974C0.15144 9.7053 0.33499 10.3995 0.609375 11.0615C1.01172 12.0322 1.60156 12.9141 2.34375 13.6567C3.08594 14.3999 3.96875 14.9888 4.9375 15.3911Z"
      fill="#1A6E95"
    />
  </svg>
);

export interface ButtonProps {
  onClick: () => void | Promise<any>;
  label?: string;
  loading?: boolean;
  type?: ButtonType;
  disabled?: boolean;
  size?: ButtonSize;
  Icon?:
    | React.ForwardRefExoticComponent<
        IconProps & React.RefAttributes<SVGSVGElement>
      >
    | CustomIcon;
  iconPosition?: IconPosition;
  fullWidth?: boolean;
  refObj?: React.RefObject<HTMLButtonElement>;
  className?: string;
}

export const Button = ({
  onClick,
  loading = false,
  disabled = false,
  label,
  type = "primary",
  fullWidth = false,
  size = "medium",
  Icon,
  refObj,
  iconPosition = "left",
  className,
}: ButtonProps) => {
  const iconSize = size === "medium" ? 24 : 20;
  const [overrideLoading, setOverrideLoading] = useState(false);
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const clickHandle = onClick();
    if (clickHandle?.then) {
      setOverrideLoading(true);
      clickHandle?.then((_) => {
        setOverrideLoading(false);
      });
    }
  };

  return (
    <button
      ref={refObj}
      disabled={disabled}
      className={clsx(
        styles.button,
        disabled && styles.disabled,
        styles[type],
        styles[size],
        !label && styles.iconOnly,
        (loading || overrideLoading) && styles.loading,
        className
      )}
      style={{ width: fullWidth ? "100%" : undefined }}
      onClick={loading || overrideLoading ? undefined : handleClick}
    >
      <div
        className={clsx(
          styles.buttonInner,
          (loading || overrideLoading) && styles.loading
        )}
      >
        {Icon && iconPosition === "left" && <Icon size={iconSize} />}
        {label}
        {Icon && iconPosition === "right" && <Icon size={iconSize} />}
      </div>
      {(loading || overrideLoading) && <Spinner />}
    </button>
  );
};

interface ButtonGroupProps {
  children?: JSX.Element | JSX.Element[];
  vertical?: boolean;
  className?: string;
  align?: "start" | "center" | "end";
}

export const ButtonGroup = ({
  children,
  vertical = false,
  align = "center",
  className,
}: ButtonGroupProps) => (
  <div
    className={clsx(
      styles.buttonGroup,
      vertical && styles.vertical,
      styles[align],
      className
    )}
  >
    {children}
  </div>
);
