import { Option } from "../../../lib/interfaces/input";
import { Button, ButtonGroup } from "../../button/Button";
import { Select } from "../../select/Select";
import { ButtonWrapper, ContentWrapper, Paragraph } from "./Styled";

interface addCareForm {
  onSubmit: () => void;
  exitWithOutSave: () => void;
  onCancel: () => void;
  isLoading: boolean;
  disabled: boolean;
}

export const ExitCarePlan = ({
  onSubmit,
  exitWithOutSave,
  onCancel,
  isLoading,
  disabled,
}: addCareForm) => {
  return (
    <ContentWrapper>
      <Paragraph>
        Your recent changes have not been saved. Would you like to save before
        exiting?
      </Paragraph>
      <ButtonWrapper>
        <ButtonGroup>
          <Button
            onClick={onCancel}
            type="secondary"
            size="small"
            label="Cancel"
          />
          <Button
            onClick={exitWithOutSave}
            type="secondary"
            size="small"
            label="leave without saving"
          />

          <Button
            onClick={onSubmit}
            type="primary"
            size="small"
            label="leave with saving"
            disabled={disabled}
            loading={isLoading}
          />
        </ButtonGroup>
      </ButtonWrapper>
    </ContentWrapper>
  );
};
