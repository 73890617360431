import styled from "styled-components";


export const EditableArea = styled.div`
  .ck-editor__editable,
  .ck-editor__editable_inline,
  .ck-editor__editable.ck-blurred {
    border: 1px solid #ccc !important; /* Force the border for editable area */
    border-radius: 0px 0px 8px 8px !important; /* Corrected: Added semicolon */
    padding: 10px;
    min-height: 200px;
    box-sizing: border-box;
  }
  .ck-editor__editable:not(.ck-blurred) {
    border-color: #ccc !important; /* Change border color on focus */
    border-radius: 0px 8px 0 0 !important; /* Corrected: Added semicolon */
    border-top: 1px solid #ccc;
  }
  .ck-toolbar {
    border-bottom: none;
    border-radius: 8px 8px 0 0 !important; /* Adjust border-radius */
  }
`;