import styles from "./style.module.css";
import { Button } from "../../../components/button/Button";
import { CaretLeft } from "phosphor-react";
import { PatientDetailProps } from "../../../lib/interfaces/carePlan";
import { careplanPatientDetailFormatDateTime } from "../../../lib/util/date";
import { BaseContext } from "../../../lib/context/context";
import { useContext } from "react";

type PatientPillarDetailProps = {
  patient: PatientDetailProps;
  title: string;
};

export const PillarCarePlanHeader = ({
  patient,
  title,
}: PatientPillarDetailProps) => {
  const { pillarData, setIsPillarPageShow, setIsPillarEditShow } =
    useContext(BaseContext);
  const { name, assignedSex, dateOfBirth, identifiers } = patient;

  return (
    <header className={styles.content}>
      <Button
        size="small"
        type="secondary-gray"
        label="Back"
        Icon={CaretLeft}
        iconPosition="left"
        onClick={() => {
          setIsPillarPageShow(false);
          setIsPillarEditShow(false);
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "32px 0px 0px 0px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            // width: "190px"
          }}
        >
          <img
            src={pillarData?.icons?.smallColorIcon}
            alt="icon"
            width={24}
            height={24}
          />
          <span style={{ marginLeft: "5px" }}>{title}</span>
        </div>
        <h1 style={{ padding: "0px", margin: "16px 0px" }}>Care Plan</h1>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>{name}</span>
          <div
            style={{
              background: "black",
              borderRadius: "50%",
              padding: "2.5px",
              margin: "0 5px",
            }}
          ></div>
          <span>
            {assignedSex.replace(/^\w/, (match) => match.toUpperCase())}
          </span>
          <div
            style={{
              background: "black",
              borderRadius: "50%",
              padding: "2.5px",
              margin: "0 5px",
            }}
          ></div>
          <span>{careplanPatientDetailFormatDateTime(dateOfBirth)}</span>

          {identifiers?.epic && (
            <>
              <div
                style={{
                  background: "black",
                  borderRadius: "50%",
                  padding: "2.5px",
                  margin: "0 5px",
                }}
              ></div>
              <span>{identifiers?.epic}</span>
            </>
          )}
        </div>
      </div>
    </header>
  );
};
